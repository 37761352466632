import { DEFAULT_PLAYBACK_SPEED } from '@/config/constants';
import useCases from '@/hooks/useCases';
import useUser from '@/hooks/useUser';
import {
  trackDisableSilenceCut,
  trackEnableSilenceCut,
  trackPauseAudio,
  trackPlayAudio,
  trackPlaybackSpeedChange,
} from '@/services/analytics';
import { activatePedals, releasePedals } from '@/services/pedals';
import { removeSilenceFromAudioData } from '@/utils/audio/silence';

import ContentCutIcon from '@mui/icons-material/ContentCut';
import SpeedIcon from '@mui/icons-material/Speed';
import CachedIcon from '@mui/icons-material/Cached';
import { Box, IconButton, SxProps, Theme, Chip, Tooltip } from '@mui/material';
import { FC, useEffect, useRef, useState } from 'react';

import { default as H5AudioPlayer } from 'react-h5-audio-player';
import 'react-h5-audio-player/lib/styles.css';
import './CaseStyles.css';

// import AudioPlayer.css file into the AudioPlayer
import './AudioPlayer.css';
import useAlerts from '@/hooks/useAlerts';

const CMD_MAPPING = {
  '1972': {
    '659': {
      index: 0,
      longPressPlay: true,
      0: 'KEY-UP',
      4: 'FAST-FORWARD',
      8: 'REWIND',
      64: 'PLAY',
      // TO-DO: Add other values
      unknown1: 'REWIND', // Left + Right
      unknown2: 'NO ACTION', // Left + Centre
      unknown3: 'FAST-FORWARD', // Centre + Right
      unknown4: 'NO ACTION', // Left + Centre + Right
    },
    '622': {
      // Olympus R32 Hand Pedals
      index: 2,
      longPressPlay: false,
      0: 'KEY-UP',
      2: 'PLAY', // Centre (custom mapping)
      4: 'REWIND', // Left
      1: 'FAST-FORWARD', // Right (custom mapping)
      // TO-DO: Add other values
      5: 'NO ACTION', // Left + Right
      6: 'NO ACTION', // Left + Centre
      3: 'NO ACTION', // Centre + Right
      7: 'NO ACTION', // Left + Centre + Right
    },
    '536': {
      index: 2,
      longPressPlay: true,
      0: 'KEY-UP', // No pedals pressed
      1: 'REWIND', // Left
      2: 'PLAY', // Right
      3: 'REWIND', // Left + Right
      4: 'FAST-FORWARD', // Centre
      5: 'NO ACTION', // Left + Centre
      6: 'FAST-FORWARD', // Centre + Right
      7: 'NO ACTION', // Left + Centre + Right
    },
  },
};

interface Props {
  src: string;

  allowSilenceCut?: boolean;
  sx?: SxProps<Theme>;
}
const AudioPlayer: FC<Props> = ({ src, allowSilenceCut = false, sx }) => {
  const audioRef = useRef<H5AudioPlayer>();

  const cases = useCases();
  const alerts = useAlerts();
  const [noSilenceUrl, setNoSilenceUrl] = useState<string>();
  const [isSilenceCut, setIsSilenceCut] = useState<boolean>(allowSilenceCut);
  const [playbackSpeed, setPlaybackSpeed] = useState<number>(DEFAULT_PLAYBACK_SPEED);
  const [audioRefetching, setAudioRefetching] = useState<boolean>(false);
  const [audioType, setAudioType] = useState<'original' | 'segment'>('original');

  const user = useUser();

  const enableSilenceCut = () => {
    if (cases.fullAudioBlob == null) {
      return;
    }
    removeSilenceFromAudioData(cases.fullAudioBlob).then((blob: Blob) => {
      setNoSilenceUrl(window.URL.createObjectURL(blob));
      setIsSilenceCut(true);
    });
  };

  const toggleSilenceCut = () => {
    if (isSilenceCut) {
      trackDisableSilenceCut(cases.caseId);
      setIsSilenceCut(false);
    } else {
      trackEnableSilenceCut(cases.caseId);
      setIsSilenceCut(true);
    }
  };

  const handlePlaybackSpeedChange = () => {
    const speeds = [0.8, 1, 1.25, 1.5, 2];
    const currentIndex = speeds.indexOf(playbackSpeed);
    const newIndex = (currentIndex + 1) % speeds.length;
    const newSpeed = speeds[newIndex];

    const audioPlayer = audioRef?.current?.audio?.current as HTMLAudioElement;
    if (!audioPlayer) return;
    audioPlayer.playbackRate = newSpeed;
    setPlaybackSpeed(newSpeed);
    trackPlaybackSpeedChange(cases.caseId, newSpeed);
  };

  /**
   * Pedals command handler.
   *
   * @param event
   */
  async function handleInputReport(event: HIDInputReportEvent) {
    const { data, device } = event;
    const audioPlayer = audioRef?.current?.audio?.current as HTMLAudioElement;
    if (!audioPlayer) return;

    const mapping = CMD_MAPPING?.[device.vendorId]?.[device.productId];
    const cmdCode = data.getUint8(mapping?.index);
    const cmd = mapping?.[cmdCode] || 'NO ACTION';

    switch (cmd) {
      case 'KEY-UP':
        if (!audioPlayer.paused && mapping?.longPressPlay) {
          audioPlayer.pause();
          audioPlayer.currentTime = Math.max(0, audioPlayer.currentTime - 1);
        }
        break;
      case 'FAST-FORWARD':
        audioRef.current.handleClickForward();
        // audioPlayer.currentTime = Math.min(audioPlayer.duration, audioPlayer.currentTime + 3);
        break;
      case 'REWIND':
        audioRef.current.handleClickRewind();
        // audioPlayer.currentTime = Math.max(0, audioPlayer.currentTime - 3);
        break;
      case 'PLAY':
        if (mapping?.longPressPlay || audioPlayer.paused) {
          audioPlayer.play();
        } else {
          audioPlayer.pause();
          audioPlayer.currentTime = Math.max(0, audioPlayer.currentTime - 1);
        }
        break;
      // Other values may be sent at other times but not sure when or how.
      default:
        break;
    }
  }

  // support for keyboard shortcuts
  const handleKeyboardShortcuts = (event: KeyboardEvent) => {
    const audioPlayer = audioRef?.current?.audio?.current as HTMLAudioElement;

    switch (event.key) {
      case 'F10': // Play/Pause
        event.preventDefault(); // Prevent any default behavior
        if (audioPlayer.paused) {
          audioPlayer.play();
        } else {
          audioPlayer.pause();
          audioPlayer.currentTime = Math.max(0, audioPlayer.currentTime - 1);
        }
        break;
      case 'F9': // Next Track
        event.preventDefault();
        audioRef.current.handleClickForward();
        break;
      case 'F8': // Rewind
        event.preventDefault();
        audioRef.current.handleClickRewind();
        break;
      default:
        break;
    }
  };

  useEffect(() => {
    if (!audioRef?.current?.audio?.current) {
      return;
    }

    allowSilenceCut && enableSilenceCut();

    if (user.isSecretary()) {
      document.addEventListener('keydown', handleKeyboardShortcuts);
      activatePedals(handleInputReport);
    }

    return () => {
      if (user.isSecretary()) {
        document.removeEventListener('keydown', handleKeyboardShortcuts);
        releasePedals(handleInputReport);
      }
    };
  }, [audioRef.current]);

  const handleOnPause = () => {
    trackPauseAudio(cases.caseId);
  };

  const handleAudioLoaded = (type: 'original' | 'segment') => {
    setAudioRefetching(false);
    setAudioType(type);
    alerts.success('Lyd hentet');
    enableSilenceCut();
  };

  const handleAudioRefetch = () => {
    setAudioRefetching(true);
    if (audioType === 'original') {
      cases.loadAudioFromSegment().then(() => handleAudioLoaded('segment'));
    } else {
      cases.loadFullAudio().then(() => handleAudioLoaded('original'));
    }
  };

  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
        width: '100%',
        p: 2,
        opacity: audioRefetching ? 0.5 : 1,
        ...sx,
      }}
    >
      <H5AudioPlayer
        src={isSilenceCut ? noSilenceUrl : src}
        ref={audioRef}
        onPlay={() => trackPlayAudio(cases.caseId)}
        onPause={handleOnPause}
        autoPlayAfterSrcChange={false}
        progressJumpSteps={{ backward: 3000, forward: 3000 }}
        timeFormat="mm:ss"
        preload="auto"
        defaultCurrentTime="Loading"
        defaultDuration="Loading"
        hasDefaultKeyBindings={false}
        customVolumeControls={[
          <>
            {allowSilenceCut && (
              <Tooltip
                title={isSilenceCut ? 'Stilhed fjernet' : 'Fjern perioder med stilhed i lyd'}
              >
                <IconButton onClick={toggleSilenceCut} size="small" sx={{ mr: 1 }}>
                  <ContentCutIcon color={isSilenceCut ? 'secondary' : 'action'} fontSize="small" />
                </IconButton>
              </Tooltip>
            )}
          </>,
          <Tooltip title="Afspilningshastighed">
            <Chip
              label={`${playbackSpeed}x`}
              icon={<SpeedIcon />}
              onClick={handlePlaybackSpeedChange}
              size="small"
            />
          </Tooltip>,
        ]}
        customAdditionalControls={[
          <>
            {allowSilenceCut && (
              <Tooltip title={'Prøv at hente lyd igen'}>
                <IconButton onClick={handleAudioRefetch} size="small" sx={{ mr: 1 }}>
                  <CachedIcon
                    color="action"
                    fontSize="small"
                    sx={{
                      animation: audioRefetching ? 'spin 1s linear infinite' : null,
                    }}
                  />
                </IconButton>
              </Tooltip>
            )}
          </>,
        ]}
      />
    </Box>
  );
};

export default AudioPlayer;
