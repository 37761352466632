import { isAdmin, isAlphaDoctor, isSecretary } from '@/services/featureFlag';
import { InboxPagev2 } from './InboxPagev2';
import { InboxPage } from './InboxPage';

export const InboxWrapper = () => {
  if (isSecretary() || isAdmin() || isAlphaDoctor()) {
    return <InboxPagev2 />;
  }
  return <InboxPage />;
};
