import {
  archiveCase,
  createCase,
  editCase,
  getCase,
  getCurrentCustomerConfig,
  getRecordedAudio,
  getTemplateIds,
  lockCaseRequest,
  storeFinalAudioRecording,
  storeRecordedAudio,
  unlockCaseRequest,
} from '@/services/BackendAPI';
import { parseRequestFromEHR, submitToEHR } from '@/services/EHRintegration';
import {
  caseFlaggedAndUpdated,
  caseFullAudioStored,
  caseLocked,
  casePredictionsRequested,
  caseSegmentAudioStored,
  caseSubmittedAndUpdated,
  caseSubmittedWithLLMAssistance,
  caseUnlocked,
  codeAdded,
  codeModifiersChanged,
  codeRemoved,
  fireMixpanelEvent,
  flaggedCaseSubmittedAndUpdated,
  trackCaseNoteUpdatedSilently,
  trackCaseReceivedFromEHR,
  trackStartAudioProcessingAttempt,
  trackStopFullRecording,
  trackStopSegmentRecording,
} from '@/services/analytics';
import AudioSegmentTranscriptionManager from '@/services/audioSegmentTranscriptionManager';
import { ECaseStatus } from '@/types/ECaseStatus';
import { EXMCaseStatusType } from '@/types/EXMCaseStatusType';
import { IAnnotatedReadCase } from '@/types/IAnnotatedReadCase';
import { ICaseLocked } from '@/types/ICaseLocked';
import { ICaseNoteStructured } from '@/types/ICaseNoteStructured';
import { ICode } from '@/types/ICode';
import { IConfigTemplate } from '@/types/IConfigTemplate';
import { ICreateCaseInput } from '@/types/ICreateCaseInput';
import { ICustomerConfig } from '@/types/ICustomerConfig';
import { IEditCaseInput } from '@/types/IEditCaseInput';
import { IParsedCase } from '@/types/IParsedCase';
import { ITerminologyCodeRead } from '@/types/ITerminologyCodeRead';
import { ITranscriptionResponse } from '@/types/ITranscriptionResponse';
import { EAudioProcessingStep } from '@/types/audio/EAudioProcessingStep';
import { EAudioRecordingType } from '@/types/audio/EAudioRecordingType';
import { IWebNewCaseData } from '@/types/web-only/IWebNewCaseData';
import { addNewCodes, cloneCode, indexCodes } from '@/utils/codes';
import { convertCaseUrgencyToXMStatusType, delay, isInsideElectron } from '@/utils/misc';
import {
  appendNoteCorrected,
  appendNoteStructured,
  calculateEditMetrics,
  customizeNoteIfNecessary,
  getDefaultNoteStructure,
  isNoteStructureEmpty,
  structuredNoteToNonJsonString,
} from '@/utils/note';
import { mapWebNewCaseDataToCreateCaseInput } from '@/utils/web-only/cases';
import { makeAutoObservable, runInAction, transaction } from 'mobx';
import { AlertStore } from './AlertStore';
import { UserStore } from './UserStore';
import fixWebmDuration from 'webm-duration-fix';
import { isLockStale } from '@/utils/dates';
import getBlobDuration from 'get-blob-duration';
import { differenceInDays, differenceInSeconds } from 'date-fns';

export class CaseStore {
  selectedCodes: ICode[] = [];
  suggestedCodes: ICode[] = [];
  existingCodes: ICode[] = [];
  codesEditMode: boolean = false;
  noteEditMode: boolean = false;
  caseId?: number;
  loaded: boolean = false;
  note: string = '';
  ehrNote: string = '';
  noteStructured: ICaseNoteStructured | null = null;
  noteCorrected: string = '';
  originalNote: string = '';
  llmEditedNote: string = '';
  rawASROutput: string[] = [];
  consultationType?: string;
  specialty?: string;
  createdAt: string | null = null;
  flaggedAt: string | null = null;
  flaggedBy?: string;
  flagReason?: string;
  externalId: string = '';
  externalNoteId: string = '';
  patientName: string = '';
  patientId: string = '';
  cpr: string = '';
  doctorId: string = '';
  doctorInitials: string = '';
  location?: string;
  xMStatusType: EXMCaseStatusType;
  status: ECaseStatus = ECaseStatus.NEW;
  confirmedNote: boolean = false;
  urgent: boolean = false;
  locked: ICaseLocked | null = null;
  submittedBy?: string;

  hasAudioOnBackend: boolean = false;
  isFullAudioLoading: boolean = false;
  isSegmentAudioLoading: boolean = false;
  fullAudioBlob: Blob | undefined = undefined;
  segmentAudioBlob: Blob | undefined = undefined;

  /**
   * Used to determine wether the case is a 'draft'
   */
  draft: boolean = false;

  /**
   * Used to redirect user to Inbox, bypassing the Unsaved Changes dialog.
   */
  forceRedirectToInbox: boolean = false;

  /**
   * Used to redirect user to the /newehr page when a new request comes from XM.
   * See also RootPage.useEffect and CasePage.useEffect.
   */
  forceRedirectToNewEhrPage: boolean = false;

  /**
   * A function that stops recording of the audio.
   * Used to reset the paused recording on receiving a new case from XM.
   */
  stopFullRecording: Function | undefined = undefined;
  stopSegmentRecording: Function | undefined = undefined;

  alertStore: AlertStore;
  userStore: UserStore;

  config?: ICustomerConfig;

  public templateIds: string[] = [];
  public webNewCaseData?: Partial<IWebNewCaseData>;

  public audioRecordingDevice?: MediaDeviceInfo;

  public flaggedCases: IAnnotatedReadCase[] = [];
  public completedCases: IAnnotatedReadCase[] = [];
  public newCases: IAnnotatedReadCase[] = [];

  /**
   * If a case is open and it has not yet been flagged or completed,
   * on sending a new case from EHR, we need to warn the user and ask to finish current case first.
   */
  public currentCaseNotSavedWarning: boolean = false;

  public fullAudioProcessingStep: EAudioProcessingStep = EAudioProcessingStep.VOID;
  public segmentAudioProcessingStep: EAudioProcessingStep = EAudioProcessingStep.VOID;

  private audioSegmentTranscriptionManager: AudioSegmentTranscriptionManager =
    new AudioSegmentTranscriptionManager();

  constructor(alertStore: AlertStore, userStore: UserStore) {
    makeAutoObservable(this);
    this.alertStore = alertStore;
    this.userStore = userStore;
  }

  ///
  /// Getters/setters.
  ///

  enableCodesEditMode() {
    this.codesEditMode = true;
  }
  disableCodesEditMode() {
    this.codesEditMode = false;
  }
  toggleCodesEditMode() {
    this.codesEditMode = !this.codesEditMode;
  }
  enableNoteEditMode() {
    this.noteEditMode = true;
  }
  disableNoteEditMode() {
    this.noteEditMode = false;
  }
  toggleNoteEditMode() {
    this.noteEditMode = !this.noteEditMode;
  }

  enableFullAudioLoading() {
    this.isFullAudioLoading = true;
  }
  disableFullAudioLoading() {
    this.isFullAudioLoading = false;
  }

  enableSegmentAudioLoading() {
    this.isSegmentAudioLoading = true;
  }

  disableSegmentAudioLoading() {
    this.isSegmentAudioLoading = false;
  }

  public setWebNewCaseData(data: Partial<IWebNewCaseData>) {
    this.webNewCaseData = data;
  }

  public setTemplateIds(templateIds: string[]) {
    this.templateIds = templateIds;
  }

  public setAudioRecordingDevice(device: MediaDeviceInfo) {
    console.debug('debug: audio recording device set', device);
    this.audioRecordingDevice = device;
  }

  public setFlaggedCases(cases: IAnnotatedReadCase[]) {
    this.flaggedCases = cases;
  }
  public setCompletedCases(cases: IAnnotatedReadCase[]) {
    this.completedCases = cases;
  }
  public setNewCases(cases: IAnnotatedReadCase[]) {
    this.newCases = cases;
  }

  public enableForceRedirectToInbox() {
    this.forceRedirectToInbox = true;
  }
  public disableForceRedirectToInbox() {
    this.forceRedirectToInbox = false;
  }
  public enableForceRedirectToNewEhr() {
    this.forceRedirectToNewEhrPage = true;
  }
  public disableForceRedirectToNewEhr() {
    this.forceRedirectToNewEhrPage = false;
  }

  public setNoteStructured(noteStructured: ICaseNoteStructured) {
    this.noteStructured = noteStructured;
  }
  public setNoteCorrected(noteCorrected: string) {
    this.noteCorrected = noteCorrected;
  }
  public appendNoteStructuredSegment(noteStructuredSegment: ICaseNoteStructured | undefined) {
    if (noteStructuredSegment == null) {
      return;
    }

    this.setNoteStructured(appendNoteStructured(this.noteStructured, noteStructuredSegment));
  }
  public appendNoteCorrectedSegment(noteCorrectedSegment: string | undefined) {
    if (noteCorrectedSegment == null) {
      return;
    }
    this.setNoteCorrected(appendNoteCorrected(this.noteCorrected, noteCorrectedSegment));
  }

  public setSelectedCodes(selectedCodes: ICode[]) {
    this.selectedCodes = selectedCodes;
  }

  public setStopFullRecordingHandler(handler: Function) {
    this.stopFullRecording = handler;
  }
  public setStopSegmentRecordingHandler(handler: Function) {
    this.stopSegmentRecording = handler;
  }

  public setFullAudioBlob(blob: Blob) {
    console.debug('debug: full recording received', blob);
    this.fullAudioBlob = blob;
  }
  public setSegmentAudioBlob(blob: Blob) {
    console.debug('debug: segment recording received', blob);
    this.segmentAudioBlob = blob;
  }

  public setAudioProcessingStep(audioType: EAudioRecordingType, step: EAudioProcessingStep) {
    console.debug('debug: audio processing step set', { audioType }, { step });
    if (audioType === EAudioRecordingType.FULL) {
      this.fullAudioProcessingStep = step;
    } else {
      this.segmentAudioProcessingStep = step;
    }
  }

  public enableCurrentCaseNotSavedWarning() {
    this.currentCaseNotSavedWarning = true;
  }
  public disableCurrentCaseNotSavedWarning() {
    this.currentCaseNotSavedWarning = false;
  }

  public hasRecordingBeenUsed() {
    return this.segmentAudioBlob != null;
  }

  ///
  /// Checks.
  ///

  /**
   * Check whether the case is new or not.
   *
   * @returns {boolean}
   */
  isNew(): boolean {
    return this.status === ECaseStatus.NEW;
  }

  /**
   * Check whether the case has any unsaved changes.
   * Used on the CasePage to not lose any changes on leaving the page.
   *
   * @returns {boolean}
   */
  isDirty(): boolean {
    // If the case comes from XM, the case is considered dirty.
    if (this.isExternalCase()) {
      return true;
    }

    // We don't show the Unsaved Changes prompt for Completed cases.
    if (this.isCompleted()) {
      return false;
    }

    // If it's a new case, created by a doctor, then the only criterion is its note field content.
    if (this.caseId == null) {
      return this.isNoteEmpty();
    }

    // If the case is not new, we check whether the edit modes for note and codes enabled.
    return this.noteEditMode || this.codesEditMode;
  }

  /**
   * Check whether the case is currently locked.
   *
   * @returns {boolean}
   */
  isLocked(): boolean {
    if (this.locked) {
      const stale = isLockStale(this.locked.date);
      return !stale;
    } else {
      return false;
    }
  }

  /**
   * Check whether the case is locked by current user.
   *
   * @returns {boolean}
   */
  isLockedByMe(): boolean {
    if (!this.isLocked()) {
      return false;
    }

    return this.locked.locked_by_me;
  }

  /**
   * Check whether the case comes from an external system, for example XMedicus.
   *
   * @returns {boolean}
   */
  isExternalCase(): boolean {
    return this.cpr.length > 0 || this.existingCodes.length > 0;
  }

  isFlagged(): boolean {
    return this.status === ECaseStatus.FLAGGED;
  }

  isCompleted(): boolean {
    return this.status === ECaseStatus.SUBMITTED;
  }

  isDraft(): boolean {
    return this.draft;
  }

  areSelectedCodesVisible(): boolean {
    return (this.selectedCodes && this.selectedCodes.length > 0) || this.loaded;
  }

  isNoteEmpty() {
    if (this.noteStructured == null) {
      return true;
    }

    return Object.keys(this.noteStructured).every(
      (key: string) => this.noteStructured[key].trim() === '',
    );
  }

  /**
   * Check if the external note ID, passed from EHR, is empty.
   * @returns
   */
  isExternalNoteIdEmpty() {
    return this.externalNoteId == null || this.externalNoteId == '' || this.externalNoteId == '0';
  }

  /**
   * Check if the external note (EHR note), passed from EHR, is empty.
   * @returns
   */
  isExternalNoteEmpty() {
    return this.ehrNote == null || this.ehrNote.trim().length === 0;
  }

  ///
  /// Other logic.
  ///

  clearCase() {
    this.tryStoppingRecording();

    this.clearAppFields();
    this.clearExternalFields();

    this.audioSegmentTranscriptionManager = new AudioSegmentTranscriptionManager();
    this.setAudioProcessingStep(EAudioRecordingType.SEGMENT, EAudioProcessingStep.VOID);
    this.setAudioProcessingStep(EAudioRecordingType.FULL, EAudioProcessingStep.VOID);
  }

  clearAppFields() {
    this.note = '';
    this.noteStructured = null;
    this.noteCorrected = '';
    this.originalNote = '';
    this.llmEditedNote = '';
    this.rawASROutput = [];
    this.selectedCodes = [];
    this.suggestedCodes = [];
    this.codesEditMode = false;
    this.noteEditMode = false;
    this.caseId = undefined;
    this.loaded = false;
    this.consultationType = undefined;
    this.specialty = undefined;
    this.status = ECaseStatus.NEW;
    this.confirmedNote = false;
    this.createdAt = null;
    this.flaggedAt = null;
    this.flaggedBy = undefined;
    this.flagReason = undefined;
    this.externalId = '';
    this.externalNoteId = '';
    this.patientId = '';
    this.doctorId = '';
    this.doctorInitials = '';
    this.location = undefined;
    this.urgent = false;
    this.submittedBy = undefined;

    // Clear audio fields.
    this.segmentAudioBlob = undefined;
    this.fullAudioBlob = undefined;
    this.hasAudioOnBackend = false;
  }

  /**
   * Clear the fields that come from an external system, e.g. XMedicus.
   */
  clearExternalFields() {
    this.patientName = '';
    this.cpr = '';
    this.existingCodes = [];
    this.ehrNote = '';
  }

  initConfig() {
    getCurrentCustomerConfig()
      .then((config) => {
        runInAction(() => {
          this.config = config;
        });
      })
      .catch((e) => this.handleRequestError(e)('Unable to load customer config'));

    this.fetchTemplateIds();
  }

  /**
   * Initialize the store based on a new case request from XM.
   * @param inputCase
   */
  initStoreWithNewCase(inputCase: any) {
    console.log(inputCase);

    let parsedCase: IParsedCase;

    try {
      parsedCase = parseRequestFromEHR(inputCase);
    } catch (e) {
      this.alertStore.error('Unable to parse incoming request');
      throw e;
    }

    trackCaseReceivedFromEHR(parsedCase);

    const existingCodes = indexCodes(parsedCase.existing_codes);

    console.log(parsedCase);

    this.consultationType = parsedCase.consultation_type;
    this.specialty = parsedCase.specialty;
    this.externalId = parsedCase.external_id;
    this.ehrNote = parsedCase.ehr_note;
    this.existingCodes = existingCodes;
    this.selectedCodes = existingCodes;
    this.patientId = parsedCase.patient_id;
    this.patientName = [parsedCase.patient_firstname, parsedCase.patient_lastname].join(' ');
    this.cpr = parsedCase.cpr;
    this.externalNoteId = parsedCase.note_id;
    this.doctorId = parsedCase.doctor_id;
    this.doctorInitials = parsedCase.doctor_initials;
    this.location = parsedCase.location;
  }

  initStoreWithExistingData(caseData: IAnnotatedReadCase, isDraft: boolean = false) {
    const mainCodes = indexCodes(caseData.main_codes);
    const suggestedCodes = indexCodes(caseData.suggested_codes);

    this.note = caseData.note;

    // If the new note_structured is null, try to restore it from note.
    if (
      isNoteStructureEmpty(caseData.note_structured) &&
      caseData.note != null &&
      caseData.note.length > 0
    ) {
      const note = caseData.note_corrected || caseData.note;
      this.noteStructured = getDefaultNoteStructure(note);
      this.noteCorrected = note;
      this.originalNote = note;
      // If the new note_structured is not null, use it.
    } else {
      this.noteStructured = caseData.note_structured;
      this.noteCorrected = caseData.note_corrected;
      this.originalNote = caseData.note_structured?.note || '';
    }
    this.caseId = caseData.id;
    this.patientId = caseData.patient_id;
    this.externalId = caseData.external_id;
    this.externalNoteId = caseData.external_note_id;
    this.consultationType = caseData.consultation_type;
    this.specialty = caseData.specialty;
    this.doctorId = caseData.doctor_id;
    this.doctorInitials = caseData.doctor_initials;
    this.location = caseData.location;
    this.selectedCodes = addNewCodes(this.selectedCodes, mainCodes);
    this.suggestedCodes = suggestedCodes;
    this.loaded = true;
    this.status = caseData.status;
    this.confirmedNote = caseData.confirmed_note;
    this.createdAt = caseData.created_at;
    this.flaggedAt = caseData.flagged_at;
    this.flaggedBy = caseData.flagged_by;
    this.flagReason = caseData.flag_reason;
    this.hasAudioOnBackend = caseData.has_audio;
    this.urgent = caseData.urgent;
    this.locked = caseData.locked;
    this.submittedBy = caseData.submitted_by;
    this.draft = isDraft;
  }

  /**
   * Create a new case in the backend.
   */
  createNewCase() {
    let inputCase: ICreateCaseInput = {
      external_id: this.externalId,
      existing_codes: this.existingCodes,
      note: this.note,
      patient_id: this.patientId,
      doctor_id: this.doctorId,
      doctor_initials: this.doctorInitials,
      consultation_type: this.consultationType,
      specialty: this.specialty,
      location: this.location,
      external_note_id: this.externalNoteId,
      status: this.status,
    };

    if (!isInsideElectron()) {
      inputCase = {
        ...inputCase,
        ...mapWebNewCaseDataToCreateCaseInput(this.webNewCaseData),
      };
    }

    console.log(inputCase);

    return createCase(inputCase, false)
      .then((caseData: IAnnotatedReadCase) => {
        this.editSuccessResponseHandler(caseData, false);
        fireMixpanelEvent('Case Created', {
          case_id: this.caseId,
          case_location: this.location,
          case_doctor: this.doctorInitials,
        });
        return Promise.resolve();
      })
      .catch(this.editErrorResponseHandler)
      .finally(() => {
        this.webNewCaseData = undefined;
      });
  }

  /**
   * Update the case on the backend and get predictions.
   *
   * @returns
   */
  updateCaseAndGenerateCodes(currentFullNote?: string, originalASRNote?: string) {
    if (this.isNoteEmpty() && currentFullNote == null) {
      this.alertStore.error('Please enter a medical note.');
      return;
    }

    let structuredNote = this.noteStructured;

    if (currentFullNote) {
      structuredNote = getDefaultNoteStructure(currentFullNote);
      this.noteStructured = structuredNote;
    }

    const editedCase: IEditCaseInput = {
      edited_codes: this.selectedCodes,
      external_note_id: this.externalNoteId,
      note_structured: structuredNote,
      note_corrected: currentFullNote,
      note: `<RAWTRANSCRIPT>\n ${this.rawASROutput.join(' ')}\n</RAWTRANSCRIPT>`,
    };

    if (originalASRNote) editedCase.note = originalASRNote;

    // Edit case and request predictions.
    return editCase(editedCase, this.caseId, true)
      .then(this.editSuccessResponseHandler)
      .catch(this.editErrorResponseHandler);
  }

  updateCaseNoteSilently(currentFullNote: string) {
    const editedCase: IEditCaseInput = {
      note_structured: {
        note: currentFullNote,
      },
    };

    this.noteStructured = getDefaultNoteStructure(currentFullNote);

    return editCase(editedCase, this.caseId, false)
      .then(() => trackCaseNoteUpdatedSilently(this.caseId))
      .catch((e) => console.error('Could not update the case note silently', e));
  }

  // method to upload the full audio to the backend
  uploadFinalAudioToBackend = async (audioBlob) => {
    storeFinalAudioRecording(this.caseId, audioBlob)
      .then(() => {
        this.alertStore.success('The full audio has been uploaded successfully');
        caseFullAudioStored(this.caseId);
        this.fullAudioBlob = audioBlob;
      })
      .catch((e) => this.handleRequestError(e)('Error occurred while uploading the full audio'));
  };

  fetchTemplateIds() {
    getTemplateIds()
      .then((configTemplate: IConfigTemplate) => {
        runInAction(() => {
          this.templateIds = configTemplate.consultation_types || [];
        });
      })
      .catch((e) =>
        this.handleRequestError(e)('Error occurred while getting the customer configuration'),
      );
  }

  /**
   * Update LLM Edited Note.
   */
  updateLLMEditedNote(updatedNote) {
    this.llmEditedNote = updatedNote;
  }

  appendDictationToRawASROutput(dictation: string) {
    this.rawASROutput.push(dictation);
  }

  /**
   * Update Confirmed Note status.
   */
  updateConfirmedNoteStatus(confirmed_note_status) {
    this.confirmedNote = confirmed_note_status;
  }

  /**
   * Archive case
   *
   *
   * @returns
   */
  deleteCase() {
    if (this.caseId == null) {
      return;
    }

    return archiveCase(this.caseId)
      .then(() => {
        this.alertStore.success('The case has been archived successfully');
        this.clearCase();
        this.forceRedirectToInbox = true;
      })
      .catch((e) => this.handleRequestError(e)(e.response.data.detail));
  }

  updateCaseUrgency(urgent: boolean) {
    if (this.caseId == null) {
      return;
    }

    return editCase({ urgent }, this.caseId)
      .then(() => {
        this.alertStore.success('The case status has been updated successfully');
        this.urgent = urgent;
      })
      .catch((e) => this.handleRequestError(e)('Error occurred while updating the case status'));
  }

  /**
   * Initialize the store based on an existing case object fetched from backend.
   *
   * @param caseId
   * @param next
   */
  fetchCase(caseId: number, isDraft: boolean, next?: Function) {
    this.clearCase();

    getCase(caseId)
      .then((caseData) => {
        this.initStoreWithExistingData(caseData, isDraft);

        // Run an additional success callback if necessary.
        if (next) {
          next();
        }
      })
      .catch((e) => this.handleRequestError(e)('Error occurred while getting case'));
  }

  updateCodePreferences = (code: ICode) => {
    // create an object in local storage with the code name as the key and increment each time the code is selected
    const codePreferences = JSON.parse(localStorage.getItem('codePreferences') || '{}');
    codePreferences[code.name] = codePreferences[code.name] ? codePreferences[code.name] + 1 : 1;
    localStorage.setItem('codePreferences', JSON.stringify(codePreferences));
  };

  addSelectedCode(code: ICode) {
    const copy = cloneCode(code);
    this.selectedCodes = this.selectedCodes.concat(copy);
    codeAdded(this.caseId, code);
    this.updateCodePreferences(code);
  }

  removeSelectedCode(code: ICode) {
    this.selectedCodes = this.selectedCodes.filter((item) => item._internalId !== code._internalId);
    codeRemoved(this.caseId, code);
  }

  getSuggestedCodes(type: string): ICode[] {
    return this.suggestedCodes.filter((code: ICode) => code.type == type);
  }

  changeCodeModifiers(modifiedCode: ICode, modifiers: ITerminologyCodeRead[] = []) {
    this.selectedCodes = this.selectedCodes.map((code: ICode) =>
      code._internalId === modifiedCode._internalId
        ? { ...code, modifiers: undefined, new_modifiers: modifiers }
        : code,
    );

    codeModifiersChanged(this.caseId, modifiedCode, modifiers);
  }

  flagOrCompleteAndSubmitCaseToEHR(
    statusFlag: EXMCaseStatusType,
    isUrgent: boolean = false,
    flagged: boolean = false,
    flagReason: string = '',
    confirmedNote: boolean = false,
    skipSendToEHR: boolean = false,
    submittedBy?: string,
    unlock?: boolean,
  ) {
    if (this.caseId == null) {
      return;
    }

    let note = structuredNoteToNonJsonString(this.noteStructured);
    note = customizeNoteIfNecessary(note, flagged, this.config.name);

    // Call EHR first to potentially get missing NoteID
    return submitToEHR(
      this.config,
      this.externalId,
      note,
      this.patientId,
      this.doctorId,
      this.selectedCodes,
      statusFlag,
      // If the case is new but already has externalNoteId,
      // it means the doctor sends us the same case the second time.
      // In that case, we need to force EHR to create a new case by sending externalNoteId = null back.
      this.isNew() && !this.isExternalNoteIdEmpty() ? null : this.externalNoteId,
      skipSendToEHR,
    )
      .then((noteId) => {
        const editedCase: IEditCaseInput = {
          edited_codes: this.selectedCodes,
          external_note_id: noteId,
          urgent: isUrgent,
          confirmed_note: confirmedNote || false,
          status: flagged ? ECaseStatus.FLAGGED : ECaseStatus.SUBMITTED,
          flag_reason: flagged ? flagReason : null,
          note_structured: this.noteStructured,
        };

        if (editedCase.status === ECaseStatus.SUBMITTED) {
          editedCase.submitted_by = submittedBy;
        }
        if (unlock) {
          editedCase.unlock = true;
        }
        return editCase(editedCase, this.caseId).then(async () => {
          const audio = this.fullAudioBlob || this.segmentAudioBlob;
          const duration = audio ? Math.floor(await getBlobDuration(audio)) : 0;

          if (flagged) {
            this.alertStore.success('The case has been flagged successfully');
            const processingTime = differenceInSeconds(new Date(), new Date(this.createdAt));

            caseFlaggedAndUpdated(this.caseId, flagReason, confirmedNote);
            fireMixpanelEvent('Case Submitted', {
              case_id: this.caseId,
              case_location: this.location,
              case_doctor: this.doctorInitials,
              case_processing_time: processingTime,
              case_urgent: this.urgent || false,
              case_confirmed: this.confirmedNote || false,
              recording_duration: duration,
              note_length: note.length,
              codes_length: this.selectedCodes.length,
            });
          } else {
            this.alertStore.success('The case has been submitted successfully');

            const processingTime = differenceInSeconds(new Date(), new Date(this.locked?.date));

            const editMetrics = calculateEditMetrics(note, this.originalNote);
            const daysSince = differenceInDays(new Date(), new Date(this.flaggedAt));

            caseSubmittedAndUpdated(this.caseId, duration, processingTime, editMetrics);

            fireMixpanelEvent('Case Completed', {
              case_id: this.caseId,
              case_location: this.location,
              case_doctor: this.doctorInitials,
              case_processing_time: processingTime,
              case_age: daysSince,
              case_urgent: this.urgent || false,
              case_confirmed: this.confirmedNote || false,
              recording_duration: duration,
              note_length: note.length,
              edit_metrics: editMetrics,
              codes_length: this.selectedCodes.length,
            });

            if (this.llmEditedNote) {
              const llmEditMetrics = calculateEditMetrics(note, this.llmEditedNote);
              caseSubmittedWithLLMAssistance(
                this.caseId,
                duration,
                processingTime,
                llmEditMetrics,
                editMetrics.characterErrorRate,
                'Aggressive',
              );
            }

            window?.cortiCodeAPI?.runOpenMedicusCommand('OpenNote', `int32:${this.externalNoteId}`);
          }
          this.clearCase();
        });
      })
      .catch((e) => {
        if (flagged) {
          this.handleRequestError(e)('Error occurred while flagging the case');
          return;
        }

        this.handleRequestError(e)('Error occurred while submitting case to EHR');
      });
  }

  completeAndSubmitCaseToEHR(submittedBy: string, unlock: boolean = false) {
    return this.refreshCaseLockStatus(this.caseId)
      .then((lockData: ICaseLocked) => {
        if (this.isCaseLockedByOther(lockData)) {
          return this.fireCaseIsLockedError();
        }

        return this.flagOrCompleteAndSubmitCaseToEHR(
          EXMCaseStatusType.NORMAL,
          undefined,
          undefined,
          undefined,
          false, // confirmedNote
          this.flagReason.includes('[ATTEST]'), // skipSendToEHR (yes, this is lazy. Shoot me)
          submittedBy,
          unlock,
        );
      })
      .catch((e) =>
        this.handleRequestError(e)('Error occurred while completing and submitting case to EHR'),
      );
  }

  saveChangesAndSubmitCaseToEHR(unlock: boolean = false) {
    if (this.caseId == null) {
      return;
    }

    return this.refreshCaseLockStatus(this.caseId)
      .then((lockData: ICaseLocked) => {
        if (this.isCaseLockedByOther(lockData)) {
          return this.fireCaseIsLockedError();
        }

        const statusFlag = convertCaseUrgencyToXMStatusType(this.urgent);

        let note = structuredNoteToNonJsonString(this.noteStructured);
        note = customizeNoteIfNecessary(note, this.isFlagged(), this.config.name);

        // Call EHR first to potentially get missing NoteID
        return submitToEHR(
          this.config,
          this.externalId,
          note,
          this.patientId,
          this.doctorId,
          this.selectedCodes,
          statusFlag,
          // If the case is new but already has externalNoteId,
          // it means the doctor sends us the same case the second time.
          // In that case, we need to force EHR to create a new case by sending externalNoteId = null back.
          this.isNew() && !this.isExternalNoteIdEmpty() ? null : this.externalNoteId,
        )
          .then((noteId) => {
            const editedCase: IEditCaseInput = {
              edited_codes: this.selectedCodes,
              external_note_id: noteId,
              note_structured: this.noteStructured,
            };

            if (unlock) {
              editedCase.unlock = true;
            }
            return editCase(editedCase, this.caseId).then(() => {
              this.alertStore.success('The changes have been saved successfully');
              flaggedCaseSubmittedAndUpdated(this.caseId);
              this.clearCase();
            });
          })
          .catch((e) => this.handleRequestError(e)('Error occurred while saving the changes'));
      })
      .catch((e) => this.handleRequestError(e)('Error occurred while saving the changes'));
  }

  public async storeAudio(
    audioType: EAudioRecordingType,
    isSelfHostedWhisper: boolean,
    segmentOrderId?: number,
    isSecondaryModel?: boolean,
  ): Promise<any> {
    if (audioType === EAudioRecordingType.FULL) {
      return await this.storeFullAudio();
    } else {
      return await this.storeAndTranscribeSegmentAudio(
        isSelfHostedWhisper,
        segmentOrderId,
        isSecondaryModel,
      );
    }
  }

  /**
   * Load audio from the backend is it exists.
   */
  loadFullAudio = () => {
    // this will run even if the case doesn't have audio, as the backend has_audio is unreliable
    this.enableFullAudioLoading();
    return getRecordedAudio(this.caseId, false)
      .then((receivedBlob: Blob) => {
        fixWebmDuration(receivedBlob).then((blob) => {
          runInAction(() => {
            this.fullAudioBlob = blob || undefined;
          });
        });
      })
      .catch((e) => {
        // In this particular case, 404 means there is no audio recorded for this case,
        // so we ignore such an error here and try to construct_from_segment.
        if (e.response.status === 404) {
          return this.loadAudioFromSegment();
        } else if (e.response.status === 404) {
          return;
        }

        this.handleRequestError(e)(
          'An error occurred while loading audio. Please try again or contact the administrator.',
        );
      })
      .finally(() => {
        this.disableFullAudioLoading();
      });
  };

  loadAudioFromSegment = () => {
    this.enableSegmentAudioLoading();
    return getRecordedAudio(this.caseId, true)
      .then((receivedBlob: Blob) => {
        runInAction(() => {
          this.fullAudioBlob = receivedBlob || undefined;
        });
      })
      .catch((e) => {
        this.handleRequestError(e)('No audio found for this case.');
      })
      .finally(() => {
        this.disableSegmentAudioLoading();
      });
  };

  /**
   * Toggle case lock.
   */
  public toggleCaseLock = () => {
    const actionFn = this.isLocked() ? this.unlockCase : this.lockCase;
    actionFn();
  };

  /**
   * Lock case to current user.
   */
  public lockCase = () => {
    lockCaseRequest(this.caseId)
      .then((data: ICaseLocked | null) => {
        caseLocked(this.caseId);

        runInAction(() => {
          this.locked = data;
        });
      })
      .catch((e) => this.handleRequestError(e)('Error occurred while locking the case'));
  };

  /**
   * Unlock case.
   */
  public unlockCase = () => {
    return unlockCaseRequest(this.caseId)
      .then((data: ICaseLocked | null) => {
        caseUnlocked(this.caseId);
        runInAction(() => {
          this.locked = data;
        });

        return Promise.resolve();
      })
      .catch((e) => {
        // Don't inform user if the case is locked by the other user.
        if (e?.response?.status === 403 && e?.response?.data?.detail?.locked_by_me === false) {
          this.locked = e.response.data.detail;
          console.error('The case has not been unlocked because it is locked by another user.');
          return Promise.resolve();
        }

        return this.handleRequestError(e)('Error occurred while unlocking the case');
      });
  };

  public async waitForFullAudioAndProcessIt(onFinish?: () => void) {
    // If it's a new case with audio, stop recording and save the full audio first.
    if (this.hasRecordingBeenUsed() && this.stopFullRecording) {
      this.stopFullRecording();
      trackStopFullRecording();
    }

    return await this.waitForAudioAndProcessIt(EAudioRecordingType.FULL, onFinish);
  }

  public async waitForSegmentAudioAndProcessIt(duration?: number) {
    const newSnippet = await this.processAudio(EAudioRecordingType.SEGMENT, duration);
    return newSnippet || '';
  }

  private async waitForAudioAndProcessIt(audioType: EAudioRecordingType, onFinish?: () => void) {
    let count = 0;
    const interval = setInterval(async () => {
      if (!this.isAudioAvailable(audioType)) {
        if (count > 4) {
          clearInterval(interval);
          // @todo: Report error?
          return;
        }

        count++;
        return;
      }

      clearInterval(interval);

      const newSnippet = await this.processAudio(audioType);

      if (onFinish) {
        await onFinish();
      }

      if (newSnippet) return newSnippet;
    }, 100);
  }

  private isAudioAvailable(audioType: EAudioRecordingType) {
    if (audioType === EAudioRecordingType.FULL) {
      return this.fullAudioBlob != null;
    } else {
      return this.segmentAudioBlob != null;
    }
  }

  private async performAudioProcessingAttempt(
    audioType: EAudioRecordingType,
    isSelfHostedWhisper: boolean,
    segmentOrderId: number,
    attempt: number,
    isSecondaryModel?: boolean,
  ) {
    trackStartAudioProcessingAttempt(this.caseId, audioType, isSelfHostedWhisper, attempt);

    let transcriptResponse;

    try {
      transcriptResponse = await this.storeAudio(
        audioType,
        isSelfHostedWhisper,
        segmentOrderId,
        isSecondaryModel,
      );
    } catch (e) {
      if (audioType === EAudioRecordingType.SEGMENT) {
        this.audioSegmentTranscriptionManager.registerFailedSegmentTranscription(segmentOrderId);
      }
      return Promise.reject(e);
    }

    if (audioType === EAudioRecordingType.SEGMENT) {
      if (transcriptResponse == null) {
        this.audioSegmentTranscriptionManager.registerFailedSegmentTranscription(segmentOrderId);
        return Promise.reject('Transcription is null');
      }

      const note = {
        note_structured: transcriptResponse?.note_structured,
        note_corrected: transcriptResponse?.note_corrected,
      };
      this.audioSegmentTranscriptionManager.registerReceivedSegmentTranscription(
        segmentOrderId,
        note,
      );
    }

    return Promise.resolve(transcriptResponse);
  }

  private async processAudio(audioType: EAudioRecordingType, duration?: number) {
    const genericUiError = 'An error has occurred. Please contact the administrator.';
    const segmentTranscriptionError = 'Apologies, the transcription failed. Please record again.';

    if (this.caseId == null) {
      console.error('The case is not saved on backend yet. The transcription cancelled.');
      this.setAudioProcessingStep(audioType, EAudioProcessingStep.VOID);
      this.alertStore.error(genericUiError);
      return;
    }

    this.setAudioProcessingStep(audioType, EAudioProcessingStep.STORING);

    const segmentOrderId =
      audioType === EAudioRecordingType.SEGMENT
        ? this.audioSegmentTranscriptionManager.nextSegmentId()
        : undefined;

    let hasError = false;
    // Try to request the self-hosted model first (Mar 24).
    try {
      const transcript = await this.performAudioProcessingAttempt(
        audioType,
        true,
        segmentOrderId,
        1,
      );

      if (audioType === EAudioRecordingType.FULL) {
        return;
      }

      // check if the transcript contains hallucination (3+ consecutive repeated words)
      const hallucination =
        transcript.note_corrected.match(/\b([A-Za-zŽžÀ-ÿ]+)(?:[\W0-9]+\1\b){2,}/i) ||
        transcript.note_corrected.match(/(([.,!?;:\n\s])+)\1{2,}/);
      // check if transcript.note_corrected is empty or whitespace
      const lostText = transcript.note_corrected.trim().length < 2 && duration > 3;

      if (hallucination || lostText) {
        lostText && console.error(`Transcription is empty and duration is ${duration}`);
        hallucination &&
          !lostText &&
          console.error(`Transcription contains hallucination ${hallucination[0]}`);
        console.log(transcript, hallucination, lostText);
        hasError = true;
      } else {
        hasError = false;
      }
    } catch (e) {
      console.error(e);
      hasError = true;
    }

    // If any error occurred, fallback to the self-hosted model (Nov-23)
    if (hasError) {
      try {
        await this.performAudioProcessingAttempt(audioType, true, segmentOrderId, 2, true);
        hasError = false;
      } catch (e) {
        console.error(e);
        hasError = true;
      }
    }

    // If any error occurred, re-try with the Azure Whisper.
    if (hasError) {
      try {
        this.alertStore.warning(
          `Temporary issues might lead to less accurate transcriptions. We're fixing it. Thanks for your understanding!'`,
        );
        await this.performAudioProcessingAttempt(audioType, false, segmentOrderId, 3);
        hasError = false;
      } catch (e) {
        console.error(e);
        hasError = true;
      }
    }

    // If any error occurred, wait for 30 seconds and call the self-hosted model again.
    if (hasError) {
      try {
        await delay(8000);

        await this.performAudioProcessingAttempt(audioType, true, segmentOrderId, 4);
        hasError = false;
      } catch (e) {
        console.error(e);
        hasError = true;
      }
    }

    if (hasError) {
      console.error('Audio processing error');
      this.setAudioProcessingStep(audioType, EAudioProcessingStep.VOID);
      this.alertStore.error(
        audioType === EAudioRecordingType.SEGMENT ? segmentTranscriptionError : genericUiError,
      );
      return;
    }

    let newSnippet: string | undefined;
    if (audioType === EAudioRecordingType.SEGMENT) {
      const note = this.audioSegmentTranscriptionManager.getCurrentTranscription();

      newSnippet = note?.note_corrected;

      transaction(() => {
        this.appendNoteStructuredSegment(note?.note_structured);
        this.appendNoteCorrectedSegment(note?.note_corrected);
      });
    }

    this.setAudioProcessingStep(audioType, EAudioProcessingStep.VOID);
    if (newSnippet) return newSnippet;
  }

  /**
   * Send full audio to the backend.
   *
   * @returns
   */
  public storeFullAudio(useSelfHosted: boolean = false) {
    if (this.fullAudioBlob == null) {
      return Promise.reject('Full audio is empty');
    }

    return storeRecordedAudio(this.caseId, this.fullAudioBlob, false, true, useSelfHosted).then(
      () => {
        caseFullAudioStored(this.caseId);
        return Promise.resolve();
      },
    );
  }

  /**
   * Send segment audio to the backend for storing and transcribing.
   *
   * @param isSelfHostedWhisper
   * @param segmentOrderId
   *
   * @returns
   */
  private storeAndTranscribeSegmentAudio(
    isSelfHostedWhisper: boolean,
    segmentOrderId?: number,
    isSecondaryModel?: boolean,
  ) {
    if (this.segmentAudioBlob == null) {
      return Promise.reject('Segment audio is empty');
    }

    return storeRecordedAudio(
      this.caseId,
      this.segmentAudioBlob,
      false,
      false,
      isSelfHostedWhisper,
      segmentOrderId,
      isSecondaryModel,
    ).then((response: ITranscriptionResponse) => {
      caseSegmentAudioStored(this.caseId);

      return Promise.resolve(response);
    });
  }

  /**
   * Get the actual case.locked field data from backend.
   *
   * Currently GET /case/id endpoint is used, which may not be optimal in terms of performance.
   * @todo Add a separate endpoint for the lock status check to optimize performance.
   *
   * @param caseId
   */
  private refreshCaseLockStatus(caseId: number): Promise<ICaseLocked> {
    return getCase(caseId).then((caseData) => {
      runInAction(() => {
        this.locked = caseData.locked;
      });

      return Promise.resolve(caseData.locked);
    });
  }

  /**
   * A common way to handle request errors.
   * Uses JS currying.
   *
   * @param e
   * @returns
   */
  private handleRequestError = (e: any) => {
    return (message: string) => {
      this.alertStore.error(message);
      console.error(e);
    };
  };

  private editSuccessResponseHandler = (
    caseData: IAnnotatedReadCase,
    hasBeenPredictionsGenerated: boolean = true,
  ) => {
    // Enable note edit mode after creating a new case.
    this.noteEditMode = true;

    this.initStoreWithExistingData(caseData);

    if (hasBeenPredictionsGenerated) {
      casePredictionsRequested(this.caseId);
    }
  };

  private editErrorResponseHandler = (e) => {
    const message = 'Error occurred while getting predictions';

    this.handleRequestError(e)(message);
    return Promise.reject(message);
  };

  private fireCaseIsLockedError() {
    return this.handleRequestError(null)(
      'We cannot process your request because the case is locked by another user.',
    );
  }

  private isCaseLockedByOther(lockData: ICaseLocked) {
    return lockData !== null && !lockData.locked_by_me;
  }

  private tryStoppingRecording() {
    // Stop recording to clear the already recorded fragment if the recording is only paused.
    if (this.stopFullRecording != null) {
      this.stopFullRecording();
      trackStopFullRecording();
      this.setStopFullRecordingHandler(undefined);
    }
    if (this.stopSegmentRecording != null) {
      this.stopSegmentRecording();
      trackStopSegmentRecording();
      this.setStopSegmentRecordingHandler(undefined);
    }
  }
}
