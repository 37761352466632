import AuthProgressIndicator from '@/components/AuthProgressIndicator';
import { initDatadogRUM } from '@/services/datadog';
import { initMixpanel } from '@/services/mixpanel';
import { authUpdateWhenComeBackOnline } from '@/services/keycloak/helpers';
import keycloak from '@/services/keycloak/init';
import { AlertStore } from '@/store/AlertStore';
import { CaseStore } from '@/store/CaseStore';
import { UserStore } from '@/store/UserStore';
import { getCustomerName } from '@/utils/misc';
import { datadogRum } from '@datadog/browser-rum';
import mixpanel from 'mixpanel-browser';
import { ReactKeycloakProvider } from '@react-keycloak/web';
import { FC, PropsWithChildren, useEffect, useState } from 'react';
import Intercom from '@intercom/messenger-js-sdk';
import { getUserHMAC } from '@/services/BackendAPI';

interface IProps {
  caseStore: CaseStore;
  alertStore: AlertStore;
  userStore: UserStore;
}
const AuthProvider: FC<PropsWithChildren<IProps>> = ({
  children,
  caseStore,
  alertStore,
  userStore,
}) => {
  const [loading, setLoading] = useState<boolean>(true);

  async function onKeycloakEvent(event: string, error: any) {
    // Wait for Keycloak to be initialized before fetching config as we need a token for auth.
    if (event == 'onReady') {
      caseStore.initConfig();

      // Load additional user data.
      keycloak.loadUserInfo().then(async (data: any) => {
        userStore.setUserData({ ...data, roles: keycloak.realmAccess?.roles });

        initDatadogRUM();
        datadogRum.setUser({
          id: data.sub,
          name: data.name,
          username: data.preferred_username,
          email: data.email,
          customer: getCustomerName(),
          role: userStore.getPrimaryRole(),
        });
        initMixpanel();
        mixpanel.identify(data.sub);

        mixpanel.people.set({
          $name: data.name,
          $email: data.email,
          customer: getCustomerName(),
          role: userStore.getPrimaryRole(),
          permissions: keycloak.realmAccess?.roles,
        });

        const userHMAC = await getUserHMAC(data.sub);
        console.log(data.sub, userHMAC);
        Intercom({
          api_base: 'https://api-iam.intercom.io',
          app_id: 'y30epli4',
          custom_launcher_selector: '#intercom_opener',
          user_id: data.sub,
          name: data.name,
          email: data.email,
          user_hash: userHMAC,
          hide_default_launcher: true,
        });
      });
    }
    if (event == 'onInitError') {
      alertStore.error('Authentication failed');
    }
    setLoading(false);
  }

  // Skip authentication for the settings route.
  if (window.location.pathname === '/settings') {
    return <>{children}</>;
  }

  const handleOnlineEvent = () => {
    authUpdateWhenComeBackOnline(keycloak);
  };

  useEffect(() => {
    window.addEventListener('online', handleOnlineEvent);

    return () => {
      window.removeEventListener('online', handleOnlineEvent);
    };
  }, []);

  return (
    <ReactKeycloakProvider
      authClient={keycloak}
      initOptions={{ onLoad: 'login-required' }}
      onEvent={onKeycloakEvent}
    >
      {!loading ? children : <AuthProgressIndicator />}
    </ReactKeycloakProvider>
  );
};

export default AuthProvider;
