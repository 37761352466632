import MicrophoneIcon from '@/assets/icons/microphone.svg?react';
import AudioPlayer from '@/components/case/AudioPlayer';
import AudioRecordingSourceControl from '@/components/case/AudioRecordingSourceControl';
import CaseInputEditControl from '@/components/case/CaseInputEditControl';
import ExternalNoteField from '@/components/case/ExternalNoteField';
import StructuredNoteField from '@/components/case/StructuredNoteField';
import { LoadingButton } from '@/components/controls/LoadingButton';
import EditProtectionDialog, { useEditProtection } from '@/components/dialogs/EditProtectionDialog';
import { CardContainer } from '@/components/layout/CardContainer';
import useAlerts from '@/hooks/useAlerts';
import useAudioRecorder from '@/hooks/useAudioRecorder';
import useCases from '@/hooks/useCases';
import { fixNoteWithLLM, storeFinalAudioRecording } from '@/services/BackendAPI';
import { isDoctor } from '@/services/featureFlag';
import { CaseStore } from '@/store/CaseStore';
import { ECaseStatus } from '@/types/ECaseStatus';
import { EAudioProcessingStep } from '@/types/audio/EAudioProcessingStep';
import { EAudioRecordingType } from '@/types/audio/EAudioRecordingType';
import { formatAudioDurationMs } from '@/utils/dates';
import { blinkStyles, flexColumnFullHeight, noteFieldTextStyles } from '@/utils/theme';
import formatText from '@/utils/transcriptReplacements';
import PauseIcon from '@mui/icons-material/Pause';
import { Box, CircularProgress, IconButton } from '@mui/material';
import { blue, green, grey } from '@mui/material/colors';
import getBlobDuration from 'get-blob-duration';
import { sanitize } from 'isomorphic-dompurify';
import { observer } from 'mobx-react';
import { FC, useEffect, useRef, useState } from 'react';

interface ICaseInputProps {}
export const CaseInput: FC<ICaseInputProps> = observer(() => {
  const cases = useCases();
  const alerts = useAlerts();
  const noteField = useRef<HTMLTextAreaElement>();
  const [fullAudioUrl, setFullAudioUrl] = useState<string>(undefined);
  const [isSubmitting, setIsSubmitting] = useState<boolean>(false);
  const [isTranscribing, setIsTranscribing] = useState<boolean>(false);
  const [isStructuredVisible, setIsStructuredVisible] = useState<boolean>(true);
  const [dictations, setDictations] = useState<string[]>([]);
  const [isFileUploaded, setIsFileUploaded] = useState<boolean>(false);
  const isEdit = location.pathname.includes('/edit');

  const { handleReadonlyElementClick, handleAcceptClick, handleCancelClick, readonlyDialogOpen } =
    useEditProtection(cases.isFlagged(), cases.confirmedNote);

  const onSpeechEnd = async (blob: Blob) => {
    // get length in seconds of blob
    setIsTranscribing(true);
    const duration = (await getBlobDuration(blob)) || 5;
    cases.setSegmentAudioBlob(blob);
    const originalTranscript = await cases.waitForSegmentAudioAndProcessIt(duration);
    if (originalTranscript.length > 0) {
      const finalDictation = formatText(originalTranscript);
      console.log('Dictation', { finalDictation, originalTranscript });
      setDictations((prevDictations) => [...prevDictations, finalDictation]);
    }
    setIsTranscribing(false);
  };

  const {
    toggleRecording,
    stopRecording,
    audioBlob,
    recordingTime,
    isRecording,
    isPaused,
    isSpeaking,
    isInitializing,
  } = useAudioRecorder(onSpeechEnd);

  useEffect(() => {
    if (isRecording) {
      cases.setAudioProcessingStep(EAudioRecordingType.FULL, EAudioProcessingStep.RECORDING);
    }
  }, [isRecording]);

  const handleRecordButtonClick = async () => {
    noteField.current?.focus();

    const MyDevice = cases.audioRecordingDevice;
    toggleRecording(MyDevice);
  };

  /**
   * Handle GenerateCodes button click.
   *
   * Note: If it's a secretary (user has come from Inbox),
   * we don't allow audio recording and hide the recording button.
   * So we can't get into this handler if it's a secretary.
   *
   * @returns
   */
  const handleGenerateCodesButtonClick = async () => {
    // Disable buttons to prevent clicking them twice.
    if (isSubmitting) {
      return;
    }
    setIsSubmitting(true);

    // Stop the recording if it's active.
    if (!audioBlob && !isFileUploaded) {
      stopRecording();
    }

    if (isDoctor()) {
      await fixNoteWithLLM(cases.noteStructured.note, cases.caseId)
        .then((updatedText) => {
          cases.updateLLMEditedNote(updatedText);
          cases.updateCaseNoteSilently(updatedText);
        })
        .catch((e) => {
          console.error('Error fixing note with LLM:', e);
        });
    }
    noteField.current?.focus();
    setIsSubmitting(false);

    // Save case data.
    cases.updateCaseAndGenerateCodes().finally(() => {
      // Activate the buttons back after generating codes.
      // Show the Suggested Codes pane.
      cases.enableCodesEditMode();
    });
  };

  useEffect(() => {
    if (audioBlob == null || isFileUploaded) {
      return;
    }

    if (cases.stopFullRecording == null) {
      // Make it possible to stop the recording from CaseStore on receiving a new case from XM.
      cases.setStopFullRecordingHandler(stopRecording);
    }

    cases.setFullAudioBlob(audioBlob);
    setFullAudioUrl(window.URL.createObjectURL(audioBlob));
    try {
      cases.setAudioProcessingStep(EAudioRecordingType.FULL, EAudioProcessingStep.STORING);
      storeFinalAudioRecording(cases.caseId, audioBlob).then(() => {
        console.log('Final audio recording stored successfully.');
        setIsFileUploaded(true);
        cases.setAudioProcessingStep(EAudioRecordingType.FULL, EAudioProcessingStep.VOID);
      });
    } catch (e) {
      alerts.error('Error storing the final audio recording:');
      console.error('Error storing the final audio recording:', e);
    }
  }, [audioBlob]);

  useEffect(() => {
    if (cases.fullAudioBlob) {
      setFullAudioUrl(window.URL.createObjectURL(cases.fullAudioBlob));
    }
  }, [cases.fullAudioBlob]);

  // prevent user from leaving the page while recording or file uploading
  useEffect(() => {
    const handleBeforeUnload = (e: BeforeUnloadEvent) => {
      if (isRecording || isFileUploaded) {
        e.preventDefault();
      }
    };

    window.addEventListener('beforeunload', handleBeforeUnload);

    return () => {
      window.removeEventListener('beforeunload', handleBeforeUnload);
      if (fullAudioUrl) {
        window.URL.revokeObjectURL(fullAudioUrl);
      }
    };
  }, [isRecording, isFileUploaded]);

  const areFieldsDisabled =
    (cases.isFlagged() && !cases.noteEditMode) || cases.isCompleted() || cases.confirmedNote;

  return (
    <Box
      component="form"
      sx={{
        ...flexColumnFullHeight,
        cursor: isSubmitting ? 'wait' : 'unset',
      }}
    >
      <Box sx={flexColumnFullHeight}>
        <Box
          sx={{
            ...flexColumnFullHeight,
            // Subtract player height (if necessary) and padding of the container from the full height.
            height: fullAudioUrl ? 'calc(100% - 130px - 36px)' : 'calc(100% - 36px)',
            width: '100%',
            maxWidth: '1400px',

            margin: 'auto',
          }}
          onClick={handleReadonlyElementClick}
        >
          <CardContainer
            sx={{ ...flexColumnFullHeight, height: 'calc(100% - 36px)' }}
            title="Journalnotat"
            menu={
              <ShowOriginalNoteController
                isStructuredVisible={isStructuredVisible}
                setIsStructuredVisible={setIsStructuredVisible}
                handleReadonlyElementClick={handleReadonlyElementClick}
              />
            }
          >
            <NoteFieldSection
              dictations={dictations}
              cases={cases}
              isStructuredVisible={isStructuredVisible}
              areFieldsDisabled={areFieldsDisabled || isSubmitting}
            />
            {isInitializing && (
              <Box
                sx={{
                  display: 'flex',
                  flexDirection: 'row',
                  justifyContent: 'center',
                  alignItems: 'center',
                  gap: 1,
                  my: 1,
                }}
              >
                <CircularProgress color="primary" size={20} />
                <span>Initialiserer...</span>
              </Box>
            )}

            {cases.isNew() && !isEdit && !fullAudioUrl && (
              <>
                {(!cases.isNoteEmpty() || isRecording) && (
                  <GenerateCodesButton
                    isSubmitting={isSubmitting || isTranscribing}
                    areFieldsDisabled={!isPaused || areFieldsDisabled || cases.isNoteEmpty()}
                    onClick={handleGenerateCodesButtonClick}
                    text={
                      isTranscribing
                        ? 'Transskriberer...'
                        : isPaused
                          ? 'Færdiggør diktat og foreslå koder'
                          : 'Lytter...'
                    }
                  />
                )}
                <Box
                  sx={{
                    display: 'grid',
                    gridTemplateColumns: '1fr auto 1fr',
                    alignItems: 'center',
                    justifyContent: 'stretch',
                    gap: 1,
                    my: 1,
                  }}
                >
                  {cases.fullAudioProcessingStep !== EAudioProcessingStep.STORING && (
                    <>
                      <RecorderDuration
                        fullRecordingTime={recordingTime}
                        isFullRecordingCurrently={isRecording && !isPaused}
                      />
                      <RecordButton
                        disabled={
                          areFieldsDisabled ||
                          isSubmitting ||
                          isInitializing ||
                          !cases.audioRecordingDevice
                        }
                        isRecording={isRecording && !isPaused}
                        isSpeaking={isSpeaking}
                        onClick={handleRecordButtonClick}
                      />
                    </>
                  )}

                  {cases.fullAudioProcessingStep === EAudioProcessingStep.VOID && (
                    <AudioRecordingSourceControl />
                  )}
                </Box>
              </>
            )}
          </CardContainer>
          {cases.isFullAudioLoading && <AudioLoader />}
          {!cases.isFullAudioLoading && fullAudioUrl && (
            <AudioPlayer
              sx={{ pt: 0 }}
              src={fullAudioUrl}
              allowSilenceCut={[ECaseStatus.FLAGGED, ECaseStatus.SUBMITTED].includes(cases.status)}
            />
          )}
        </Box>
      </Box>

      <EditProtectionDialog
        title="Vil du redigere dit notat?"
        open={readonlyDialogOpen}
        onCancel={handleCancelClick}
        onAccept={(e) => {
          handleAcceptClick(e);
          cases.toggleNoteEditMode();
          cases.updateConfirmedNoteStatus(false);
          // For some reason, the textarea doesn't get focused here, so I need to wait for a few ms to set the focus.
          // @todo: Find a reason of why it doesn't get focused here in a normal way noteField.current?.focus() and fix if possible.
          setTimeout(() => {
            noteField.current?.focus();
          }, 100);
        }}
      />
    </Box>
  );
});

const AudioLoader: FC = () => (
  <Box sx={{ textAlign: 'center', py: 4 }}>
    <CircularProgress color="primary" />
  </Box>
);

const ShowOriginalNoteController = ({
  handleReadonlyElementClick,
}: {
  isStructuredVisible: boolean;
  setIsStructuredVisible: (isVisible: boolean) => void;
  handleReadonlyElementClick: (e: React.MouseEvent<HTMLElement, MouseEvent>) => void;
}) => (
  <Box sx={{ display: 'flex', flexDirection: 'row', gap: '1rem' }}>
    <CaseInputEditControl onClick={handleReadonlyElementClick} />
  </Box>
);

const NoteFieldSection = observer(
  ({
    dictations,
    cases,
    isStructuredVisible,
    areFieldsDisabled,
  }: {
    dictations: string[];
    cases: CaseStore;
    isStructuredVisible: boolean;
    areFieldsDisabled: boolean;
  }) => (
    <>
      {cases.isNew() && !cases.isExternalNoteIdEmpty() && !cases.isExternalNoteEmpty() && (
        <ExternalNoteField note={cases.ehrNote} />
      )}

      {isStructuredVisible ? (
        <StructuredNoteField
          dictations={dictations}
          note={cases.noteStructured}
          readOnly={areFieldsDisabled}
        />
      ) : (
        <div
          data-dd-action-name="Edit note"
          style={{
            ...(flexColumnFullHeight as any),
            // paddingLeft: '0.8rem',
            // paddingRight: '0.8rem',
            padding: 0,
            ...noteFieldTextStyles,
            overflowY: 'auto',
            width: '100%',
            whiteSpace: 'pre-wrap',
          }}
          dangerouslySetInnerHTML={{
            __html:
              cases.noteCorrected && cases.noteCorrected.length > 0
                ? sanitize(cases.noteCorrected)
                : 'Not available',
          }}
        />
      )}
    </>
  ),
);

const RecordButton = ({
  isRecording,
  isSpeaking,
  disabled,
  onClick,
}: {
  isRecording: boolean;
  isSpeaking: boolean;
  disabled: boolean;
  onClick: React.MouseEventHandler<HTMLButtonElement>;
}) => {
  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
        width: '56px',
        height: '56px',
      }}
    >
      <IconButton
        aria-label="record"
        onClick={onClick}
        data-testid="record-button"
        sx={{
          backgroundColor: isRecording ? green[50] : blue[50],
          borderColor: isRecording ? green[600] : blue[600],
          color: isRecording ? green[600] : blue[600],
          px: '12px',
          py: '12px',
          borderWidth: 1,
          boxShadow: isSpeaking ? '0 0 12px 3px rgba(67,160,71, 0.7)' : 'none',
          transition: 'all 0.3s',
          borderStyle: 'solid',
          '&:hover': {
            backgroundColor: isRecording ? green[100] : blue[100],
            borderColor: isRecording ? green[700] : blue[700],
          },
          '&:disabled': {
            backgroundColor: grey[300],
            borderColor: grey[500],
            color: grey[400],
          },
        }}
        disabled={disabled}
      >
        {isRecording ? (
          <PauseIcon
            sx={{
              ...blinkStyles,
              animation: isRecording ? 'blinker 1s linear infinite' : 'none',
            }}
            color="inherit"
            width={20}
            height={20}
          />
        ) : disabled ? (
          <CircularProgress size={20} color="inherit" />
        ) : (
          <MicrophoneIcon width={20} height={20} title="Record" />
        )}
      </IconButton>
    </Box>
  );
};

const RecorderDuration = ({
  fullRecordingTime,
  isFullRecordingCurrently,
}: {
  fullRecordingTime: number;
  isFullRecordingCurrently: boolean;
}) => (
  <Box
    sx={{
      color: isFullRecordingCurrently ? green[600] : blue[600],
      fontSize: '0.8rem',
      textAlign: 'right',
      fontVariantNumeric: 'tabular-nums',
    }}
    className="dd-privacy-allow"
  >
    {formatAudioDurationMs(fullRecordingTime)}
  </Box>
);

const GenerateCodesButton = ({
  isSubmitting,
  areFieldsDisabled,
  onClick,
  text,
}: {
  isSubmitting: boolean;
  areFieldsDisabled: boolean;
  onClick: React.MouseEventHandler<HTMLButtonElement>;
  text: string;
}) => (
  <LoadingButton
    loading={isSubmitting}
    type="button"
    variant="contained"
    size="large"
    color="success"
    onClick={onClick}
    disabled={areFieldsDisabled}
    sx={{
      mt: 1,
      minWidth: '10rem',
      gap: 1,
      '& .MuiLoadingButton-loadingIndicator': {
        position: 'static',
      },
    }}
    className="dd-privacy-allow"
  >
    {text}
  </LoadingButton>
);
