import { checkPrerequisites } from './analytics';
import { isDevelopment } from '@/utils/env';

import mixpanel from 'mixpanel-browser';

const clientToken = checkPrerequisites()
  ? '4e7ae9a9ac2dc65370bd122935e79da4'
  : '4e7ae9a9ac2dc65370bd122935e79da4';

export function initMixpanel() {
  // Near entry of your product, init Mixpanel
  mixpanel.init(clientToken, {
    debug: isDevelopment(),
    track_pageview: true,
    ignore_dnt: true,
    persistence: 'localStorage',
  });
}
