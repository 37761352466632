import { observer } from 'mobx-react';
import useCases from '@/hooks/useCases';
import { MDXEditorMethods } from '@mdxeditor/editor';
import EditorComponent from './EditorComponent';
import AudioControls from './AudioControls';
import { useEffect, useRef, useState, useCallback } from 'react';
import { containerStyle, editorContainerStyle } from './CaseInputv2Styles';
import { useStreamedSpeechRec } from './useStreamedSpeechRec';
import AudioPlayer from './AudioPlayer';
import { fixDictation } from './DictationUtils';
import { fixMarkdownText } from '@/utils/transcriptReplacements';
import { isDoctor } from '@/services/featureFlag';
import { fixNoteWithLLM } from '@/services/BackendAPI';
import useAlerts from '@/hooks/useAlerts';

export const CaseInputv2 = observer(() => {
  const cases = useCases();
  const alerts = useAlerts();
  const [isLoading, setIsLoading] = useState(false);
  const textboxRef = useRef<MDXEditorMethods>(null);

  const lastUpdateTime = useRef<number>(performance.now());

  const handleEditorChanges = useCallback(
    (markdown: string) => {
      cases.setNoteStructured({ note: markdown });
      // Get the current time
      const currentTime = performance.now();
      // If more than 10 seconds have passed since the last update, update the note
      if (currentTime - lastUpdateTime.current > 15000) {
        cases.updateCaseNoteSilently(markdown);
        lastUpdateTime.current = currentTime; // Update the last updated time
      }
    },
    [cases],
  );

  const addDictation = useCallback((dictation: string) => {
    dictation = fixDictation(dictation);
    insertIntoEditor(dictation);
    cases.appendDictationToRawASROutput(dictation);
  }, []);

  const handleInsertTemplate = useCallback((templateText: string) => {
    templateText = fixMarkdownText(templateText);
    const templateAdmonition = `:::note\n${templateText}\n:::\n\n`;
    insertIntoEditor(templateAdmonition);
  }, []);

  const insertIntoEditor = useCallback((text: string) => {
    const textArea = textboxRef.current;

    if (!textArea) return;
    textArea.focus();
    if (textArea.getMarkdown().trim() === '') {
      textArea.setMarkdown(text);
    } else {
      textArea.insertMarkdown(text);
    }
  }, []);

  const { toggleRecording, stopRecording, recordingTime, state, audioBlob, dictationError } =
    useStreamedSpeechRec(addDictation);

  useEffect(() => {
    if (audioBlob) {
      cases.uploadFinalAudioToBackend(audioBlob);
    }
  }, [audioBlob, cases]);

  useEffect(() => {
    if (dictationError) {
      alerts.error(dictationError);
    }
  }, [dictationError, alerts]);

  const handleFinalizeRecording = useCallback(async () => {
    const textArea = textboxRef.current;

    if (isLoading || !textArea) {
      return;
    }
    setIsLoading(true);

    // Here's why we need to wait 3 seconds before pausing: https://github.com/corticph/code-frontend/pull/683
    await new Promise((resolve) => setTimeout(resolve, 3000));
    stopRecording();

    let updatedText = cases.noteStructured.note;

    if (isDoctor()) {
      try {
        updatedText = await fixNoteWithLLM(cases.noteStructured.note, cases.caseId);
        textArea.focus();
        textArea.setMarkdown(updatedText);
      } catch (e) {
        console.error('Error fixing note with LLM:', e);
      }
    }
    // Save case data
    try {
      await cases.updateCaseAndGenerateCodes(updatedText, cases.noteStructured.note);
    } catch (e) {
      console.error('Error updating case and generating codes:', e);
    }
    cases.enableCodesEditMode();
    setIsLoading(false);
  }, [isLoading, stopRecording, cases]);

  const showFinalizeButton =
    !isLoading &&
    (state === 'paused' || (state === 'idle' && textboxRef.current?.getMarkdown().length > 20));

  return (
    <div style={{ ...containerStyle, cursor: isLoading ? 'progress' : 'auto' }}>
      <div style={editorContainerStyle}>
        <EditorComponent
          note={cases.noteStructured.note}
          handleEditorChanges={handleEditorChanges}
          handleInsertTemplate={handleInsertTemplate}
          readOnly={isLoading}
          ref={textboxRef}
        />
      </div>
      {state !== 'stopped' ? (
        <AudioControls
          state={state}
          recordingTime={recordingTime}
          toggleRecording={() => toggleRecording(cases.audioRecordingDevice)}
          audioBlob={audioBlob}
          showFinalizeButton={showFinalizeButton}
          handleFinalizeRecording={handleFinalizeRecording}
        />
      ) : audioBlob ? (
        <AudioPlayer src={URL.createObjectURL(audioBlob)} />
      ) : null}
    </div>
  );
});
