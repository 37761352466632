import useCases from '@/hooks/useCases';
import { truncateString } from '@/utils/strings';
import PriorityHighIcon from '@mui/icons-material/PriorityHigh';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import {
  Box,
  Button,
  ButtonBase,
  Card,
  CardContent,
  FormControlLabel,
  Popper,
  Switch,
} from '@mui/material';
import { red, blue } from '@mui/material/colors';
import { observer } from 'mobx-react';
import CaseDataBarItem from './CaseDataBarItem';
import { useState } from 'react';
import { isAlphaSecretary } from '@/services/featureFlag';

export const CaseFlagReason = observer(({ urgent }) => {
  const cases = useCases();

  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);

  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(anchorEl ? null : event.currentTarget);
  };

  const color = urgent ? red : blue;

  return (
    <>
      <ButtonBase
        disabled={!isAlphaSecretary()}
        onClick={handleClick}
        sx={{
          display: 'flex',
          flexDirection: 'row',
          justifyContent: 'flex-start',
          maxWidth: '560px',
          border: '1px solid ' + color[500],
          borderRadius: '8px',
          cursor: 'pointer',
          overflow: 'hidden',
          '& > *': {
            pointerEvents: 'none',
          },
        }}
      >
        {urgent && (
          <Box
            sx={{
              color: color[500],
              backgroundColor: color[50],
              display: 'flex',
              alignItems: 'center',
              alignSelf: 'stretch',
            }}
          >
            <PriorityHighIcon color="inherit" sx={{ width: '24px', height: '24px' }} />
          </Box>
        )}
        <CaseDataBarItem title="Bemærkninger" value={truncateString(cases.flagReason, 160)} />
        {isAlphaSecretary() && (
          <ArrowDropDownIcon
            sx={{
              transform: !!anchorEl ? 'rotate(180deg)' : 'rotate(0deg)',
              transition: 'transform 0.3s',
            }}
          />
        )}
      </ButtonBase>
      <FlagPopper anchorEl={anchorEl} setAnchorEl={setAnchorEl} />
    </>
  );
});

const FlagPopper = observer(({ anchorEl, setAnchorEl }) => {
  const cases = useCases();

  // Use local state and only set in global state on accept
  const [urgent, setUrgent] = useState(cases.urgent);
  const changes = urgent !== cases.urgent;
  const submit = () => {
    changes && cases.updateCaseUrgency(urgent);
    setAnchorEl(null);
  };

  return (
    <Popper open={!!anchorEl} anchorEl={anchorEl}>
      <Card>
        <CardContent sx={{ display: 'flex', flexDirection: 'column', gap: 2, padding: 2 }}>
          <FormControlLabel
            sx={{ w: '100%' }}
            control={<Switch color="error" checked={urgent} onChange={() => setUrgent(!urgent)} />}
            label="Markér denne sag som hastende"
          />
          <Button variant={changes ? 'contained' : 'outlined'} color="secondary" onClick={submit}>
            {changes ? 'Gem Ændringer' : 'Luk uden at gemme'}
          </Button>
        </CardContent>
      </Card>
    </Popper>
  );
});
