import { CasePage } from '@/pages/CasePage';
import { ErrorPage } from '@/pages/ErrorPage';
import { HistoryPage } from '@/pages/HistoryPage';
import { InboxWrapper } from '@/pages/InboxWrapper';
import { RootPage } from '@/pages/RootPage';
import { SettingsPage } from '@/pages/SettingsPage';
import { removeLeadingSlash } from '@/utils/strings';
import { RouteObject } from 'react-router-dom';
import {
  PATH_EDIT_CASE,
  PATH_INBOX,
  PATH_INBOX_COMPLETED,
  PATH_NEW_CASE,
  PATH_NEW_CASE_FROM_XM,
  PATH_READONLY_CASE,
  PATH_SETTINGS,
} from './paths';

const routes: RouteObject[] = [
  {
    path: '/',
    element: <RootPage />,
    errorElement: <ErrorPage />,
    children: [
      {
        path: removeLeadingSlash(PATH_INBOX),
        element: <InboxWrapper />,
      },
      {
        path: removeLeadingSlash(PATH_INBOX_COMPLETED),
        element: <HistoryPage />,
      },
      {
        path: removeLeadingSlash(PATH_NEW_CASE),
        element: <CasePage />,
      },
      // When a case comes from XM, we redirect user to /newehr for clarity.
      {
        path: removeLeadingSlash(PATH_NEW_CASE_FROM_XM),
        element: <CasePage />,
      },
      // @todo: Replace /edit with /cases/:id/edit for clarity.
      {
        path: removeLeadingSlash(PATH_EDIT_CASE),
        element: <CasePage />,
      },
      // @todo: Replace /readonly with /cases/:id/readonly for clarity.
      {
        path: removeLeadingSlash(PATH_READONLY_CASE),
        element: <CasePage />,
      },
      {
        path: removeLeadingSlash(PATH_SETTINGS),
        element: <SettingsPage />,
      },
    ],
  },
];

export default routes;
