import { BACKEND_URL } from '@/config/constants';
import { ECaseStatus } from '@/types/ECaseStatus';
import { IAnnotatedReadCase } from '@/types/IAnnotatedReadCase';
import { ICaseLocked } from '@/types/ICaseLocked';
import { ICasesRequestParams } from '@/types/ICasesRequestParams';
import { IConfigTemplate } from '@/types/IConfigTemplate';
import { ICreateCaseInput } from '@/types/ICreateCaseInput';
import { ICustomerConfig } from '@/types/ICustomerConfig';
import { IEditCaseInput } from '@/types/IEditCaseInput';
import { IEditCaseParams } from '@/types/IEditCaseParams';
import { ITerminologyCodeRead } from '@/types/ITerminologyCodeRead';
import { ITerminologySearchParams } from '@/types/ITerminologySearchParams';
import { ITranscriptionResponse } from '@/types/ITranscriptionResponse';
import { getCustomerName } from '@/utils/misc';
import axios, { AxiosResponse } from 'axios';
import { formatISO } from 'date-fns';
import keycloak from './keycloak/init';
import { getAggressiveFixerPrompt } from './prompt';
import { calculateEditMetrics } from '@/utils/note';
import { trackLLMFixerUsed } from './analytics';

function getCustomerHeader() {
  const customer = getCustomerName();
  return { 'X-CUSTOMER': customer };
}

const Backend = axios.create({
  baseURL: BACKEND_URL,
  headers: { ...getCustomerHeader() },
});

Backend.interceptors.request.use(function (config) {
  if (config.headers) {
    config.headers['Authorization'] = 'Bearer ' + keycloak.token;
  }
  return config;
});

Backend.interceptors.response.use(
  function (response) {
    return response;
  },
  function (error) {
    return Promise.reject(error);
  },
);

export async function createCase(
  input: ICreateCaseInput,
  generatePredictions: boolean = true,
): Promise<IAnnotatedReadCase> {
  const promise = Backend.post('/cases', input, {
    params: {
      generate_predictions: generatePredictions,
    },
  });

  return new Promise((resolve, reject) => {
    promise
      .then((response: AxiosResponse<IAnnotatedReadCase>) => {
        resolve(response.data);
      })
      .catch((e) => {
        reject(e);
      });
  });
}

export async function editCase(
  input: IEditCaseInput,
  caseId: number,
  generatePredictions: boolean = false,
): Promise<IAnnotatedReadCase> {
  if (!caseId) {
    console.error('Case ID is required for editing a case.');
    return;
  }
  const queryParams: IEditCaseParams = {};
  if (generatePredictions) {
    queryParams.generate_predictions = generatePredictions;
  }

  console.log('Edit case input:', input);

  const promise = Backend.patch(`/cases/${caseId}`, input, { params: queryParams });

  return new Promise((resolve, reject) => {
    promise
      .then((response: AxiosResponse<IAnnotatedReadCase>) => {
        const { data } = response;
        if (data.locked?.locked_by_me === false) {
          alert(`Denne sag er låst af en anden bruger (${data.locked.name}). Prøv igen senere.`);
          console.error('Case is locked by another user');
          reject('Case is locked by another user');
        }
        resolve(response.data);
      })
      .catch((e) => {
        const name = e.response?.data?.details?.name;
        name
          ? alert(`Denne sag er låst af en anden bruger (${name || 'Unknown'}). Prøv igen senere.`)
          : alert('Der skete en fejl. Tjek venligst din netværksforbindelse.');
        console.error(e);
        reject(e);
      });
  });
}

export async function archiveCase(caseId: number): Promise<IAnnotatedReadCase> {
  const promise = Backend.patch(
    `/cases/${caseId}`,
    { archived: true },
    // {
    //   params: { customer_archived: getCustomerName() },
    // },
  );

  return new Promise((resolve, reject) => {
    promise
      .then((response: AxiosResponse<IAnnotatedReadCase>) => {
        resolve(response.data);
      })
      .catch((e) => {
        reject(e);
      });
  });
}

export async function getCase(caseId: number): Promise<IAnnotatedReadCase> {
  const promise = Backend.get(`/cases/${caseId}`);

  return new Promise((resolve, reject) => {
    promise
      .then((response: AxiosResponse<IAnnotatedReadCase>) => {
        resolve(response.data);
      })
      .catch((e) => {
        reject(e);
      });
  });
}

export async function getCodes(params: { code_system?: string }): Promise<ITerminologyCodeRead[]> {
  const promise = Backend.get(`/terminology`, { params });

  return new Promise((resolve, reject) => {
    promise
      .then((response: AxiosResponse<ITerminologyCodeRead[]>) => {
        resolve(response.data);
      })
      .catch((e) => {
        reject(e);
      });
  });
}

export async function searchForCode(
  query: string,
  types?: string[],
  codeSystems?: string[],
): Promise<ITerminologyCodeRead[]> {
  const params: ITerminologySearchParams = {
    query,
  };
  if (types) {
    params.type = types;
  }
  if (codeSystems && codeSystems.length > 0) {
    params.code_system = codeSystems;
  }

  const promise = Backend.get(`/terminology/search`, { params });

  return new Promise((resolve, reject) => {
    promise
      .then((response: AxiosResponse<ITerminologyCodeRead[]>) => {
        resolve(response.data);
      })
      .catch((e) => {
        reject(e);
      });
  });
}

export async function getCurrentCustomerConfig(): Promise<ICustomerConfig> {
  const promise = Backend.get('/config/me');

  return new Promise((resolve, reject) => {
    promise
      .then((response: AxiosResponse<ICustomerConfig>) => {
        resolve(response.data);
      })
      .catch((e) => {
        reject(e);
      });
  });
}

export async function getTemplateIds(): Promise<IConfigTemplate> {
  const promise = Backend.get('/config/template');

  return new Promise((resolve, reject) => {
    promise
      .then((response: AxiosResponse<IConfigTemplate>) => {
        resolve(response.data);
      })
      .catch((e) => {
        reject(e);
      });
  });
}

export async function getNewCases(
  startDate?: Date | null,
  endDate?: Date | null,
  query?: string | null,
): Promise<IAnnotatedReadCase[]> {
  // Default date range is 7 days - excluding today.
  const day = new Date();
  const yesterday = new Date();
  yesterday.setDate(day.getDate() - 1);
  const sevenDaysAgo = new Date();
  sevenDaysAgo.setDate(day.getDate() - 7);
  sevenDaysAgo.setHours(0, 0, 0, 0);

  const params: ICasesRequestParams = {
    status: ECaseStatus.NEW,
    to_date: formatISO(yesterday.setHours(23, 59, 59)),
    from_date: formatISO(sevenDaysAgo),
  };
  if (startDate) {
    params.from_date = formatISO(startDate);
  }
  if (endDate) {
    params.to_date = formatISO(endDate);
  }
  if (query) {
    params.query = query;
  }

  const promise = Backend.get('/cases', { params });

  return new Promise((resolve, reject) => {
    promise
      .then((response: AxiosResponse<IAnnotatedReadCase[]>) => {
        resolve(response.data);
      })
      .catch((e) => {
        reject(e);
      });
  });
}

export async function getFlaggedCases(
  startDate?: Date | null,
  endDate?: Date | null,
  query?: string | null,
): Promise<IAnnotatedReadCase[]> {
  const params: ICasesRequestParams = {
    status: ECaseStatus.FLAGGED,
  };
  if (startDate) {
    params.from_date = formatISO(startDate);
  }
  if (endDate) {
    params.to_date = formatISO(endDate);
  }
  if (query) {
    params.query = query;
  }

  const promise = Backend.get('/cases', {
    params,
  });

  return new Promise((resolve, reject) => {
    promise
      .then((response: AxiosResponse<IAnnotatedReadCase[]>) => {
        // Testing with mass load
        // const outputArray = [...Array(500).fill(response.data).flat()].map((item) => {
        //   return {
        //     ...item,
        //     id: Math.floor(Math.random() * 10000000000),
        //   };
        // });
        // resolve(outputArray);

        resolve(response.data);
      })
      .catch((e) => {
        reject(e);
      });
  });
}

export async function getSubmittedCases(
  startDate?: Date | null,
  endDate?: Date | null,
  query?: string | null,
): Promise<IAnnotatedReadCase[]> {
  const params: ICasesRequestParams = {
    status: ECaseStatus.SUBMITTED,
  };
  if (startDate) {
    params.from_date = formatISO(startDate);
  }
  if (endDate) {
    params.to_date = formatISO(endDate);
  }
  if (query) {
    params.query = query;
  }

  const promise = Backend.get('/cases', { params });

  return new Promise((resolve, reject) => {
    promise
      .then((response: AxiosResponse<IAnnotatedReadCase[]>) => {
        resolve(response.data);
      })
      .catch((e) => {
        reject(e);
      });
  });
}

export async function storeRecordedAudio(
  caseId: number,
  audio: Blob,
  shouldAppend: boolean,
  skipTranscription: boolean,
  useSelfHosted: boolean,
  segmentOrderId?: number,
  isSecondaryModel?: boolean,
): Promise<ITranscriptionResponse> {
  const formData = new FormData();
  formData.append('audio_file', audio);
  formData.append('shw', useSelfHosted.toString());
  if (!isSecondaryModel) formData.append('shw_ft', useSelfHosted.toString());

  if (skipTranscription) {
    formData.append('skip_transcription', skipTranscription.toString());
  }

  if (segmentOrderId != null) {
    formData.append('segment_order_id', segmentOrderId.toString());
  }

  const fn = shouldAppend ? Backend.patch : Backend.post;

  const promise = fn(`/cases/${caseId}/audio`, formData, {
    headers: {
      'Content-Type': 'multipart/form-data',
    },
    // @todo: Review the timeout when have more time.
    timeout: 30 * 60 * 1000, // 30 minutes.
  });

  return new Promise((resolve, reject) => {
    promise
      .then((response: AxiosResponse<any>) => {
        resolve(response.data);
      })
      .catch((e) => {
        reject(e);
      });
  });
}

export async function storeFinalAudioRecording(
  caseId: number,
  audio: Blob,
): Promise<ITranscriptionResponse> {
  const formData = new FormData();
  formData.append('audio_file', audio);
  formData.append('skip_transcription', 'true');

  const promise = Backend.post(`/cases/${caseId}/audio`, formData, {
    headers: {
      'Content-Type': 'multipart/form-data',
    },
    // @todo: Review the timeout when have more time.
    timeout: 30 * 60 * 1000, // 30 minutes.
  });

  return new Promise((resolve, reject) => {
    promise
      .then((response: AxiosResponse<any>) => {
        resolve(response.data);
      })
      .catch((e) => {
        reject(e);
      });
  });
}

export async function getRecordedAudio(
  caseId: number,
  construct_from_segments: boolean,
): Promise<Blob> {
  const promise = Backend.get(`/cases/${caseId}/audio`, {
    ...(construct_from_segments && { params: { construct_from_segments } }),
    responseType: 'blob',
    // @todo: Review the timeout when have more time.
    timeout: 30 * 60 * 1000, // 30 minutes.
  });

  return new Promise((resolve, reject) => {
    promise
      .then((response: AxiosResponse<Blob>) => {
        console.log(response.data);
        resolve(response.data);
      })
      .catch((e) => {
        reject(e);
      });
  });
}

export async function lockCaseRequest(caseId: number): Promise<ICaseLocked> {
  const promise = Backend.post(`/cases/${caseId}/lock`);

  return new Promise((resolve, reject) => {
    promise
      .then((response: AxiosResponse<undefined>) => {
        resolve(response.data);
      })
      .catch((e) => {
        reject(e);
      });
  });
}
export async function unlockCaseRequest(caseId: number): Promise<undefined> {
  const promise = Backend.post(`/cases/${caseId}/unlock`);

  return new Promise((resolve, reject) => {
    promise
      .then((response: AxiosResponse<undefined>) => {
        resolve(response.data);
      })
      .catch((e) => {
        reject(e);
      });
  });
}

export async function fixNoteWithLLM(text: string, caseId: number): Promise<string> {
  // if text is too short, return it as is
  if (text.length < 100) {
    return Promise.reject('Notat er for kort');
  }

  const requestStart = performance.now();

  text = text.replaceAll(':::note\n', '');
  text = text.replaceAll('\n:::', '');

  const prompt = getAggressiveFixerPrompt(text);
  const promise = Backend.post(
    '/cases/prompt-passthrough-gpt4o',
    JSON.stringify({
      messages: [
        {
          role: 'system',
          content:
            'Du er en korrekturlæser, der retter danske kliniske notater. Correct the text below.',
        },
        {
          role: 'user',
          content: prompt,
        },
      ],
    }),
    {
      headers: {
        'Content-Type': 'application/json',
      },
    },
  );

  return new Promise((resolve, reject) => {
    promise
      .then((response: AxiosResponse<{ content: string }>) => {
        let fixedText = response.data.content;
        if (!fixedText) {
          console.error('LLM Fix failed', response.data);
          reject();
        }
        // remove "<corrected_note>\n" and "\n</corrected_note>" from the response
        fixedText = fixedText.replace('<corrected_note>\n', '');
        fixedText = fixedText.replace('\n</corrected_note>', '');
        // replace "{tab}" with "&#x20;\t\t\t"
        fixedText = fixedText.replaceAll('{tab}', '&#x20;\t\t\t');

        const requestTime = Math.round(performance.now() - requestStart);
        const metrics = calculateEditMetrics(text, fixedText);
        trackLLMFixerUsed(caseId, requestTime, metrics);

        if (metrics.characterErrorRate < 0.33) {
          resolve(fixedText);
        } else {
          console.error('LLM Fix failed', metrics);
          reject();
        }
      })
      .catch((e) => {
        console.error(e);
        reject();
      });
  });
}

export async function getCoPilotAuthToken(): Promise<string> {
  const promise = Backend.get('/copilot-auth');

  return new Promise((resolve, reject) => {
    promise
      .then((response: AxiosResponse<{ access_token: string }>) => {
        console.log(response.data);
        resolve(response.data.access_token);
      })
      .catch((e) => {
        reject(e);
      });
  });
}

export async function getUserHMAC(userID: string): Promise<string> {
  const promise = Backend.get(`/config/hmac/${userID}`);

  return new Promise((resolve, reject) => {
    promise
      .then((response: AxiosResponse<string>) => {
        resolve(response.data);
      })
      .catch((e) => {
        reject(e);
      });
  });
}

// export async function getAudioTranscript(
//   caseId: number,
//   useSelfHosted: boolean,
// ): Promise<ITranscribeResponse> {
//   const responsePromise = Backend.post(
//     `/cases/${caseId}/transcript`,
//     { shw: useSelfHosted },
//     // @todo: Review the timeout when have more time.
//     { timeout: 30 * 60 * 1000 }, // 30 mins timeout
//   );

//   return new Promise((resolve, reject) => {
//     responsePromise
//       .then((response: AxiosResponse<ITranscribeResponse>) => {
//         resolve(response.data);
//       })
//       .catch((e) => {
//         reject(e);
//       });
//   });
// }
