import { Box, IconButton } from '@mui/material';
import { FC } from 'react';
import InboxTabs from './InboxTabs';
import { WelcomeBox } from './WelcomeBox';
import { isAdmin } from '@/services/featureFlag';
import SupportIcon from '@mui/icons-material/Support';

const InboxTopBarContent: FC = () => (
  <Box
    sx={{
      display: 'flex',
      flexDirection: 'row',
      gap: 6,
      justifyContent: 'space-between',
      alignItems: 'center',
      flexGrow: 1,
    }}
  >
    <WelcomeBox />
    <Box sx={{ display: 'flex', flexDirection: 'row', gap: 1, alignItems: 'center' }}>
      <InboxTabs />
      {isAdmin() && (
        <IconButton id="intercom_opener">
          <SupportIcon />
        </IconButton>
      )}
    </Box>
  </Box>
);
export default InboxTopBarContent;
