import InboxTopBarContent from '@/components/inbox/InboxTobBarContent';
import { ContentWrapper } from '@/components/layout/ContentWrapper';
import MainLayout from '@/components/layout/MainLayout';
import { TABLE_REFRESH_TIMEOUT } from '@/config/constants';
import { PATH_EDIT_CASE, PATH_INBOX } from '@/config/paths';
import useAlerts from '@/hooks/useAlerts';
import useCases from '@/hooks/useCases';
import useUser from '@/hooks/useUser';

import useFetchAllCodes from '@/hooks/useFetchAllCodes';
import useUserPreferences from '@/hooks/useUserPreferences';
import useWindowSize from '@/hooks/useWindowSize';
import { getFlaggedCases, getNewCases } from '@/services/BackendAPI';
import { requestPedals } from '@/services/pedals';
import { ITableFilterOptions } from '@/types/ITableFilterOptions';
import { daDK } from '@mui/x-data-grid/locales';

import { collectFilterValues, mapReceivedCaseToTableCase } from '@/utils/tables';
import { flexColumnFullHeight } from '@/utils/theme';
import { Box, Chip, Button, Tooltip } from '@mui/material';
import { blue } from '@mui/material/colors';
import { observer } from 'mobx-react';
import { isAlphaSecretary, isDoctor } from '@/services/featureFlag';

import { useEffect, useMemo, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { formatDistanceToNow } from 'date-fns';
import {
  DataGridPro,
  GridColDef,
  GridToolbarColumnsButton,
  GridToolbarQuickFilter,
} from '@mui/x-data-grid-pro';
import { FilterList, Lock } from '@mui/icons-material';
import { ChipInputValue } from '@/components/tables/ChipFilterInputValue';

const defaultFilterOptions: ITableFilterOptions = {
  location: [],
  specialty: [],
  doctor_initials: [],
  consultation_type: [],
  status: [],
  locked: [
    { value: true, label: 'Låst' },
    { value: false, label: 'Ikke låst' },
  ],
};

export const InboxPagev2 = observer(() => {
  const cases = useCases();
  useFetchAllCodes(cases.config?.code_systems);
  const alerts = useAlerts();
  const user = useUser();
  const userPreferences = useUserPreferences();
  const navigate = useNavigate();
  const size = useWindowSize();
  const [filterOptions, setFilterOptions] = useState<ITableFilterOptions>(defaultFilterOptions);
  const [loading, setLoading] = useState<boolean>(false);
  const [showFilters, setShowFilters] = useState<boolean>(true);

  const handleRowClick = async (caseId: number, isDraft: boolean) => {
    cases.fetchCase(caseId, isDraft, () => {
      cases.enableCodesEditMode();
      cases.loadFullAudio();
    });
    await requestPedals();
    navigate(PATH_EDIT_CASE);
  };

  const sortedAndFilteredCases = useMemo(() => {
    // Map cases data to table fields.
    const mappedFlaggedCases =
      cases.flaggedCases?.map((data) => mapReceivedCaseToTableCase(data, false)) || [];

    // Map NEW cases to table fields
    const mappedNewCases =
      cases.newCases?.map((data) => mapReceivedCaseToTableCase(data, true)) || [];

    // Merge new cases with flagged cases
    const combinedNewAndFlagged = userPreferences.showDrafts
      ? mappedNewCases.concat(mappedFlaggedCases)
      : mappedFlaggedCases;

    // Collect values for filters.
    const options = collectFilterValues(combinedNewAndFlagged, defaultFilterOptions);
    setFilterOptions(options);

    return combinedNewAndFlagged;
  }, [cases.flaggedCases, cases.newCases, userPreferences.showDrafts]);

  const windowHeightWithoutHeader = size.height - 80;

  const loadCases = (
    indicateLoading: boolean = true,
    startDate: Date | null,
    endDate: Date | null,
    query: string | null,
  ) => {
    if (indicateLoading) {
      setLoading(true);
    }

    Promise.all([
      getNewCases(startDate, endDate, query),
      getFlaggedCases(startDate, endDate, query),
    ])
      .then(([newCases, flaggedCases]) => {
        cases.setNewCases(newCases);
        cases.setFlaggedCases(flaggedCases);
      })
      .catch((e) => {
        console.error(e);
        alerts.error(
          'We cannot load the cases at the moment. Please try again or contact the administrator',
        );
      })
      .finally(() => {
        if (indicateLoading) {
          setLoading(false);
        }
      });
  };

  useEffect(() => {
    userPreferences.setLastInboxPath(PATH_INBOX);

    const startDate = userPreferences.flaggedCasesFilters?.from_date || null;
    const endDate = userPreferences.flaggedCasesFilters?.to_date || null;
    const query = userPreferences.flaggedCasesFilters?.query || null;

    // Load cases on page load and display loading indicator.
    loadCases(true, startDate, endDate, query);

    // Load cases regularly.
    const intervalId = setInterval(
      () => loadCases(false, startDate, endDate, query),
      TABLE_REFRESH_TIMEOUT,
    );
    return () => clearInterval(intervalId);
  }, [
    userPreferences.flaggedCasesFilters?.from_date,
    userPreferences.flaggedCasesFilters?.to_date,
    userPreferences.flaggedCasesFilters?.query,
    userPreferences.setLastInboxPath,
  ]);

  const columns: GridColDef[] = [
    {
      field: 'status',
      headerName: 'Status',
      minWidth: 120,
      type: 'singleSelect',
      valueOptions: filterOptions.status,
      renderCell: (params) => {
        if (params.value === 'Haster') {
          return <Chip label="Haster" color="error" />;
        } else if (params.value === 'Kladde') {
          return <Chip label="Kladde" color="secondary" />;
        } else {
          return <Chip label="Normal" color="primary" variant="outlined" />;
        }
      },
      renderHeaderFilter: (params) => {
        return <ChipInputValue {...params} />;
      },
    },
    {
      field: 'created_at',
      type: 'date',
      display: 'flex',
      headerName: 'Dato',
      minWidth: 160,
      valueGetter: (value) => new Date(value),
      renderCell: (params) => {
        // show the number of days since the case was created, with the date as a tooltip
        const createdDate = new Date(params.value as number);
        const formattedDate = createdDate.toLocaleDateString('da-DK', {
          day: 'numeric',
          month: 'short',
          year: 'numeric',
          hour: 'numeric',
          minute: 'numeric',
        });
        const distance = formatDistanceToNow(createdDate, {
          addSuffix: true,
        });

        return (
          <div>
            {formattedDate}
            <br />
            <small style={{ opacity: 0.5 }}>{distance}</small>
          </div>
        );
      },
    },
    { field: 'patient_id', headerName: 'Patient ID', width: 120 },
    { field: 'external_id', headerName: 'Journal ID', width: 120 },
    {
      field: 'doctor_initials',
      headerName: 'Læge',
      width: 140,
      type: 'singleSelect',
      valueOptions: filterOptions.doctor_initials,
      renderHeaderFilter: (params) => {
        return <ChipInputValue {...params} />;
      },
    },
    { field: 'flag_reason', headerName: 'Bemærkninger', width: 180 },
    {
      field: 'consultation_type',
      headerName: 'Konsultationstype',
      width: 180,
      type: 'singleSelect',
      valueOptions: filterOptions.consultation_type,
      renderHeaderFilter: (params) => {
        return <ChipInputValue {...params} />;
      },
    },
    {
      field: 'specialty',
      headerName: 'Speciale',
      width: 150,
      type: 'singleSelect',
      valueOptions: filterOptions.specialty,
      renderHeaderFilter: (params) => {
        return <ChipInputValue {...params} />;
      },
    },
    {
      field: 'location',
      headerName: 'Lokation',
      width: 150,
      type: 'singleSelect',
      valueOptions: filterOptions.location,
      renderHeaderFilter: (params) => {
        return <ChipInputValue {...params} />;
      },
    },
    {
      field: 'locked',
      headerName: 'Låst',
      display: 'flex',
      type: 'singleSelect',
      valueOptions: filterOptions.locked,
      valueGetter: (value) => !!value,
      renderCell: (params) => {
        if (!params.value) {
          return null;
        }

        return (
          <Tooltip title={params.row.locked} arrow>
            <Lock />
          </Tooltip>
        );
      },
    },
    { field: 'id', headerName: 'ID', width: 90, type: 'string', description: 'Corti ID' },
  ];

  if (!user.loaded) return;

  return (
    <MainLayout topBarContent={<InboxTopBarContent />}>
      <ContentWrapper
        wrapperSx={{
          ...flexColumnFullHeight,
          height: 'auto',
          width: '100vw',
          overflowX: 'overlay',
          maxHeight: windowHeightWithoutHeader,
        }}
        sx={flexColumnFullHeight}
      >
        <Box sx={{ overflowX: 'auto', w: '100%', minHeight: '100%' }}>
          <DataGridPro
            rows={sortedAndFilteredCases}
            initialState={
              isDoctor() &&
              user.initials && {
                filter: {
                  filterModel: {
                    items: [
                      { field: 'doctor_initials', operator: 'isAnyOf', value: [user.initials] },
                    ],
                  },
                },
              }
            }
            sortModel={userPreferences.inboxSortModel}
            onSortModelChange={userPreferences.setInboxSortModel}
            filterModel={userPreferences.inboxFiltersModel}
            onFilterModelChange={userPreferences.setInboxFiltersModel}
            columns={columns}
            pagination
            pageSizeOptions={[100]}
            disableRowSelectionOnClick
            autosizeOnMount
            loading={loading}
            headerFilters={showFilters}
            slots={{
              headerFilterMenu: null,
              toolbar: QuickSearchToolbar,
            }}
            scrollbarSize={20}
            localeText={daDK.components.MuiDataGrid.defaultProps.localeText}
            slotProps={{
              toolbar: {
                showQuickFilter: true,
                showColumnsButton: true,
                setShowFilters,
                showFilters,
              },
            }}
            onRowClick={(params) => handleRowClick(params.row.id, params.row.isNew)}
            sx={{
              width: '100%',
              '& .MuiDataGrid-row': {
                cursor: 'pointer',
                '&:hover': {
                  backgroundColor: blue[50],
                },
              },
              '& .datagrid--status--urgent': {
                backgroundColor: '#fff5f5',
                '&:hover': {
                  backgroundColor: '#ffe3e3',
                },
              },
              '& .MuiDataGrid-cell:focus': {
                outline: 'transparent',
              },
            }}
            getRowClassName={(params) =>
              `datagrid--status--${params.row.urgent ? 'urgent' : 'normal'}`
            }
            autosizeOptions={{
              includeOutliers: true,
              includeHeaders: true,
            }}
          />
        </Box>
      </ContentWrapper>
    </MainLayout>
  );
});

const QuickSearchToolbar = observer(({ setShowFilters, showFilters }) => {
  const userPreferences = useUserPreferences();

  return (
    <Box
      display="flex"
      justifyContent="space-between"
      sx={{
        p: 0.5,
        pb: 0,
      }}
    >
      <Box gap={1} className="dd-privacy-allow">
        <GridToolbarColumnsButton />
        <Button
          onClick={() => setShowFilters(!showFilters)}
          color={showFilters ? 'secondary' : 'inherit'}
        >
          <FilterList />
          {showFilters ? 'Skjul filtre' : 'Vis filtre'}
        </Button>
        {isAlphaSecretary() && (
          <Button onClick={() => userPreferences.setShowDrafts(!userPreferences.showDrafts)}>
            {userPreferences.showDrafts ? 'Skjul kladder' : 'Vis kladder'}
          </Button>
        )}
      </Box>
      <Box sx={{ display: 'flex', alignItems: 'center' }}>
        <GridToolbarQuickFilter />
      </Box>
    </Box>
  );
});
