import * as React from 'react';
import FormControl from '@mui/material/FormControl';
import InputLabel from '@mui/material/InputLabel';
import Select, { SelectChangeEvent } from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import {
  GridRenderHeaderFilterProps,
  gridFilterModelSelector,
  useGridSelector,
  useGridApiContext,
} from '@mui/x-data-grid-pro';
import { Box, Chip, IconButton } from '@mui/material';
import ClearIcon from '@mui/icons-material/Clear';

const getDefaultFilter = (field: string) => ({ field, operator: 'isAnyOf' });

export function ChipInputValue(props: GridRenderHeaderFilterProps) {
  const { colDef } = props;
  const apiRef = useGridApiContext();
  const filterModel = useGridSelector(apiRef, gridFilterModelSelector);
  const currentFieldFilters = React.useMemo(
    () => filterModel.items?.filter(({ field }) => field === colDef.field),
    [colDef.field, filterModel.items],
  );

  const handleChange = React.useCallback(
    (event: SelectChangeEvent) => {
      if (!event.target.value || event.target.value.length === 0) {
        if (currentFieldFilters[0]) {
          apiRef.current.deleteFilterItem(currentFieldFilters[0]);
        }
        return;
      }
      apiRef.current.upsertFilterItem({
        ...(currentFieldFilters[0] || getDefaultFilter(colDef.field)),
        value: event.target.value,
        id: currentFieldFilters[0]?.id ?? `filter-${colDef.field}`,
      });
    },
    [apiRef, colDef.field, currentFieldFilters],
  );

  const value = currentFieldFilters[0]?.value ?? [];
  const options = (colDef as any).valueOptions ?? [];
  const label = 'Indeholder';

  const isValueNotInOptions = value.length > 0 && !value.every((v: string) => options.includes(v));

  return (
    <Box display="flex" alignItems="center" sx={{ width: '100%' }}>
      {isValueNotInOptions ? (
        <Chip label={value[0]} size="small" sx={{ mr: 3, mt: 1 }} />
      ) : (
        <FormControl variant="standard" sx={{ m: 1, minWidth: 100 }} fullWidth>
          <InputLabel>{label}</InputLabel>

          <Select value={value} multiple onChange={handleChange} label={label} fullWidth>
            {options.map((option: string) => (
              <MenuItem
                key={option}
                value={option}
                sx={{
                  '&.Mui-selected': {
                    backgroundColor: (theme) => theme.palette.secondary.light,
                  },
                }}
              >
                {option}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
      )}
      {value.length > 0 && (
        <IconButton
          size="small"
          sx={{
            ml: -2,
            mt: 2,
          }}
          onClick={() => {
            apiRef.current.deleteFilterItem(currentFieldFilters[0]);
          }}
        >
          <ClearIcon
            sx={{
              fontSize: 14,
            }}
          />
        </IconButton>
      )}
    </Box>
  );
}
