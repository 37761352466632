import InboxTopBarContent from '@/components/inbox/InboxTobBarContent';
import { ContentWrapper } from '@/components/layout/ContentWrapper';
import MainLayout from '@/components/layout/MainLayout';
import SortableTableHead from '@/components/tables/SortableTableHead';
import TableHeadFilters from '@/components/tables/TableHeadFilters';
import TableRowLoading from '@/components/tables/TableRowLoading';
import TableRowNoCasesFound from '@/components/tables/TableRowNoCasesFound';
import { TABLE_REFRESH_TIMEOUT } from '@/config/constants';
import { PATH_INBOX_COMPLETED, PATH_READONLY_CASE } from '@/config/paths';
import useAlerts from '@/hooks/useAlerts';
import useCases from '@/hooks/useCases';
import useUserPreferences from '@/hooks/useUserPreferences';
import useWindowSize from '@/hooks/useWindowSize';
import { getSubmittedCases } from '@/services/BackendAPI';
import { requestPedals } from '@/services/pedals';
import { EOrder } from '@/types/EOrder';
import { ISelectOption } from '@/types/ISelectOption';
import { ITableCase } from '@/types/ITableCase';
import { ITableFilterOptions } from '@/types/ITableFilterOptions';
import { ITableHeadCell } from '@/types/ITableHeadCell';
import { createSortComparatorFn } from '@/utils/sort';
import {
  addFilterValueToFilters,
  collectFilterValues,
  createSearchFilterFn,
  mapReceivedCaseToTableCase,
} from '@/utils/tables';
import { cardContainerStyles, flexColumnFullHeight, urgentCaseTableRowStyles } from '@/utils/theme';
import { Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from '@mui/material';
import { observer } from 'mobx-react';
import { MouseEvent, useEffect, useMemo, useState } from 'react';
import { useNavigate } from 'react-router-dom';

const headCells: readonly ITableHeadCell[] = [
  {
    id: 'created_at',
    label: 'Dato',
    sortable: true,
  },
  {
    id: 'patient_id',
    label: 'Patient ID',
  },
  {
    id: 'external_id',
    label: 'Journal ID',
  },
  {
    id: 'doctor_initials',
    label: 'Læge',
    sortable: true,
  },
  {
    id: 'flag_reason',
    label: 'Bemærkninger',
  },
  {
    id: 'consultation_type',
    label: 'Konsultationstype',
  },
  {
    id: 'specialty',
    label: 'Speciale',
  },
  {
    id: 'location',
    label: 'Lokation',
  },
  {
    id: 'submitted_by',
    label: 'Indsendt af',
    sortable: true,
  },
];

const defaultFilterOptions: ITableFilterOptions = {
  location: [],
  specialty: [],
  doctor_initials: [],
  consultation_type: [],
  status: [],
};

export const HistoryPage = observer(() => {
  const cases = useCases();
  const alerts = useAlerts();
  const userPreferences = useUserPreferences();
  const navigate = useNavigate();
  const [filterOptions, setFilterOptions] = useState<ITableFilterOptions>(defaultFilterOptions);
  const [loading, setLoading] = useState<boolean>(false);

  const size = useWindowSize();

  const handleRowClick = async (caseId: number) => {
    cases.fetchCase(caseId, false, () => {
      cases.loadFullAudio();
    });

    await requestPedals();
    navigate(PATH_READONLY_CASE);
  };

  const handleFilterChange = (field: string, selectedOption: ISelectOption) => {
    const newFilters = addFilterValueToFilters(
      userPreferences.completedCasesFilters,
      selectedOption,
      field,
    );
    userPreferences.setCompletedCasesFilters(newFilters);
  };

  const handleSort = (event: MouseEvent<unknown>, property: string) => {
    const isAsc =
      userPreferences.completedCasesOrder?.orderBy === property &&
      userPreferences.completedCasesOrder?.order === EOrder.ASC;
    userPreferences.setCompletedCasesOrder(property, isAsc ? EOrder.DESC : EOrder.ASC);
  };

  const handleDateFilterChange = (filterStartDate: Date | null, filterEndDate: Date | null) => {
    userPreferences.setCompletedCasesFilters({
      from_date: filterStartDate,
      to_date: filterEndDate,
    });
  };

  const handleSearch = (searchPhrase: string) => {
    userPreferences.setCompletedCasesFilters({ query: searchPhrase });
  };

  const sortedAndFilteredCases = useMemo(() => {
    // Map cases data to table fields.
    const mappedCases = cases.completedCases?.map((c) => mapReceivedCaseToTableCase(c)) || [];

    // Collect values for filters.
    const options = collectFilterValues(mappedCases, defaultFilterOptions);
    setFilterOptions(options);

    // Filter and sort.
    return mappedCases
      .filter(createSearchFilterFn(userPreferences.completedCasesFilters))
      .sort(
        createSortComparatorFn(
          userPreferences.completedCasesOrder?.order,
          userPreferences.completedCasesOrder?.orderBy,
        ) as (a: any, b: any) => number,
      );
  }, [
    cases.completedCases,
    userPreferences.completedCasesFilters,
    userPreferences.completedCasesOrder?.order,
    userPreferences.completedCasesOrder?.orderBy,
  ]);

  const windowHeightWithoutHeader = size.height - 80;

  const loadCases = (
    indicateLoading: boolean = true,
    startDate: Date | null,
    endDate: Date | null,
    query: string | null,
  ) => {
    if (indicateLoading) {
      setLoading(true);
    }
    getSubmittedCases(startDate, endDate, query)
      .then((results) => {
        cases.setCompletedCases(results);
      })
      .catch((e) => {
        console.error(e);
        alerts.error(
          'We cannot load the cases at the moment. Please try again or contact the administrator',
        );
      })
      .finally(() => {
        if (indicateLoading) {
          setLoading(false);
        }
      });
  };

  useEffect(() => {
    userPreferences.setLastInboxPath(PATH_INBOX_COMPLETED);

    const startDate = userPreferences.completedCasesFilters?.from_date || null;
    const endDate = userPreferences.completedCasesFilters?.to_date || null;
    const query = userPreferences.completedCasesFilters?.query || null;

    // Load cases on page load and display loading indicator.
    loadCases(true, startDate, endDate, query);

    // Load cases regularly.
    const intervalId = setInterval(
      () => loadCases(false, startDate, endDate, query),
      TABLE_REFRESH_TIMEOUT,
    );
    return () => clearInterval(intervalId);
  }, [
    userPreferences.completedCasesFilters?.from_date,
    userPreferences.completedCasesFilters?.to_date,
    userPreferences.completedCasesFilters?.query,
  ]);

  return (
    <MainLayout topBarContent={<InboxTopBarContent />}>
      <ContentWrapper
        wrapperSx={{
          ...flexColumnFullHeight,
          height: 'auto',
          maxHeight: windowHeightWithoutHeader,
        }}
        sx={flexColumnFullHeight}
      >
        <TableContainer
          sx={{
            ...cardContainerStyles,
            width: 'auto',
            p: 0,
          }}
        >
          <Table aria-label="Completed cases" sx={{ p: 1, tableLayout: 'auto' }}>
            <TableHead sx={{ position: 'sticky', top: 0, backgroundColor: 'white' }}>
              <TableHeadFilters
                colSpan={headCells.length}
                handleSearch={handleSearch}
                handleFilterChange={handleFilterChange}
                handleDateFilterChange={handleDateFilterChange}
                filterOptions={filterOptions}
                filterValues={userPreferences.completedCasesFilters}
                rowCount={sortedAndFilteredCases.length}
              />
              <SortableTableHead
                cells={headCells}
                order={userPreferences.completedCasesOrder?.order}
                orderBy={userPreferences.completedCasesOrder?.orderBy}
                onRequestSort={handleSort}
              />
            </TableHead>
            <TableBody>
              {loading && <TableRowLoading colSpan={headCells.length} />}
              {!loading && sortedAndFilteredCases.length === 0 && (
                <TableRowNoCasesFound colSpan={headCells.length} />
              )}
              {sortedAndFilteredCases.map((tableCase: ITableCase) => (
                <TableRow
                  hover
                  key={tableCase.id}
                  sx={{
                    '&:last-child td, &:last-child th': { border: 0 },
                    ...urgentCaseTableRowStyles(tableCase.urgent),
                  }}
                  onClick={() => {
                    handleRowClick(tableCase.id);
                  }}
                >
                  <TableCell sx={{ width: '280px' }}>
                    {tableCase.created_at_formatted || 'N/A'}
                  </TableCell>
                  <TableCell sx={{ width: '140px' }}>{tableCase.patient_id || 'N/A'}</TableCell>
                  <TableCell sx={{ width: '140px' }}>{tableCase.external_id || 'N/A'}</TableCell>
                  <TableCell sx={{ width: '140px' }}>
                    {tableCase.doctor_initials || 'N/A'}
                  </TableCell>
                  <TableCell sx={{ minWidth: '320px', wordBreak: 'break-word' }}>
                    {tableCase.flag_reason || 'N/A'}
                  </TableCell>
                  <TableCell sx={{ width: '250px' }}>
                    {tableCase.consultation_type || 'N/A'}
                  </TableCell>
                  <TableCell sx={{ width: '250px' }}>{tableCase.specialty || 'N/A'}</TableCell>
                  <TableCell sx={{ width: '250px' }}>{tableCase.location || 'N/A'}</TableCell>
                  <TableCell sx={{ width: '140px' }}>{tableCase.submitted_by || 'N/A'}</TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      </ContentWrapper>
    </MainLayout>
  );
});
