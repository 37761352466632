import { ICode } from '@/types/ICode';
import { IParsedCase } from '@/types/IParsedCase';
import { ITerminologyCodeRead } from '@/types/ITerminologyCodeRead';
import { EAudioRecordingType } from '@/types/audio/EAudioRecordingType';
import { isDevelopment, isTest } from '@/utils/env';
import { datadogRum } from '@datadog/browser-rum';
import mixpanel from 'mixpanel-browser';

export function fireMixpanelEvent(name: string, params?: object) {
  if (params) {
    mixpanel.track(name, params);
  } else {
    mixpanel.track(name);
  }
}

export function fireEvent(name: string, params?: object) {
  if (!checkPrerequisites()) {
    if (params) {
      console.log('Event: ' + name, params);
    } else {
      console.log('Event: ' + name);
    }
    return;
  }
  datadogRum.addAction(name, params);
}

export function casePredictionsRequested(caseId: number) {
  fireEvent('Case Predictions Requested', getAnalyticsCase(caseId));
}

export function codeRemoved(caseId: number, code: ICode) {
  let caseObj = getAnalyticsCase(caseId);
  fireEvent('Code Removed', { ...caseObj, code });
}

export function codeAdded(caseId: number, code: ICode) {
  let caseObj = getAnalyticsCase(caseId);
  fireEvent('Code Added', { ...caseObj, code });
}

export function codeSearched(caseId: number, query: string) {
  let caseObj = getAnalyticsCase(caseId);
  fireEvent('Code Searched', { ...caseObj, query });
}

export function codeModifierSearched(caseId: number, code: string, query: string) {
  let caseObj = getAnalyticsCase(caseId);
  fireEvent('Code Modifier Searched', { ...caseObj, code, query });
}

export function codeModifiersChanged(
  caseId: number,
  code: ICode,
  modifiers: ITerminologyCodeRead[],
) {
  let caseObj = getAnalyticsCase(caseId);
  fireEvent('Code Modifiers Changed', {
    ...caseObj,
    code: code.name,
    internalCodeId: code._internalId,
    modifiers,
  });
}

export function codeSearchResultAdded(caseId: number, query: string, code: ICode) {
  let caseObj = getAnalyticsCase(caseId);
  fireEvent('Code Search Result Added', {
    ...caseObj,
    query,
    code,
  });
}

export function caseSubmittedAndUpdated(
  caseId: number,
  audioLength: number,
  processingTime: number,
  editMetrics: {
    originalNoteLength: number;
    submittedNoteLength: number;
    characterErrorRate: number;
    editDistance: number;
  },
) {
  fireEvent('Case Submitted and Updated', {
    ...getAnalyticsCase(caseId),
    audioLength,
    processingTime,
    ...editMetrics,
  });
}
export function caseSubmittedWithLLMAssistance(
  caseId: number,
  audioLength: number,
  processingTime: number,
  editMetrics: {
    originalNoteLength: number;
    submittedNoteLength: number;
    characterErrorRate: number;
    editDistance: number;
  },
  originalCER?: number,
  llmMode?: string,
) {
  fireEvent('Case Submitted with LLM Assistance', {
    ...getAnalyticsCase(caseId),
    audioLength,
    processingTime,
    ...editMetrics,
    originalCER,
    llmMode,
  });
}

export function flaggedCaseSubmittedAndUpdated(caseId: number) {
  fireEvent('Flagged Case Submitted and Updated', getAnalyticsCase(caseId));
}

export function feedbackProvided(caseId: number, feedback: number, scale: 5) {
  fireEvent('Feedback Provided', { ...getAnalyticsCase(caseId), feedback, scale });
}

export function caseFlaggedAndUpdated(caseId: number, reason: string, confirmed_note: boolean) {
  let caseObj = getAnalyticsCase(caseId);
  fireEvent('Case Flagged and Updated', { ...caseObj, reason, confirmed_note });
}

export function trackCaseNoteUpdatedSilently(caseId: number) {
  fireEvent('Case note updated silently', getAnalyticsCase(caseId));
}

export function trackLLMFixerUsed(
  caseId: number,
  requestLatency: number,
  metrics: {
    originalNoteLength: number;
    submittedNoteLength: number;
    characterErrorRate: number;
    editDistance: number;
  },
) {
  fireEvent('LLM Fixer Used', { ...getAnalyticsCase(caseId), requestLatency, ...metrics });
}

function getAnalyticsCase(caseId: number) {
  return { caseId };
}

export function checkPrerequisites(): boolean {
  if (isDevelopment() || isTest()) {
    return false;
  }

  return true;
}

export function caseFullAudioStored(caseId: number) {
  fireEvent('Full audio stored', getAnalyticsCase(caseId));
}
export function caseSegmentAudioStored(caseId: number) {
  fireEvent('Segment audio stored', getAnalyticsCase(caseId));
}
export function caseFullAudioStoringRetry(caseId: number, retryAttempt: number) {
  fireEvent('Full audio storing retry', { ...getAnalyticsCase(caseId), retryAttempt });
}
export function caseSegmentAudioStoringRetry(caseId: number, retryAttempt: number) {
  fireEvent('Segment audio storing retry', { ...getAnalyticsCase(caseId), retryAttempt });
}
export const trackStartAudioProcessingAttempt = (
  caseId: number,
  audioType: EAudioRecordingType,
  isSelfHostedWhisper: boolean,
  attempt: number,
) => {
  if (audioType === EAudioRecordingType.FULL) {
    trackStoringFullAudioRequested(caseId, isSelfHostedWhisper, attempt);
  } else {
    trackStoringAndTranscribingSegmentAudioRequested(caseId, isSelfHostedWhisper, attempt);
  }
};

export function caseLocked(caseId: number) {
  fireEvent('Case locked', getAnalyticsCase(caseId));
}
export function caseUnlocked(caseId: number) {
  fireEvent('Case unlocked', getAnalyticsCase(caseId));
}

export function trackCaseReceivedFromEHR(parsedCase: IParsedCase) {
  fireEvent('Case received from EHR', { externalID: parsedCase.external_id });
}

export function trackStartRecording(microphoneName: string) {
  fireEvent('Recording started', { microphoneName });
}
export function trackStartSegmentRecording() {
  fireEvent('Segment recording started');
}
export function trackStopFullRecording() {
  fireEvent('Full recording stopped');
}
export function trackStopSegmentRecording() {
  fireEvent('Segment recording stopped');
}

export function trackPauseResumeFullRecording(isPaused: boolean) {
  if (isPaused) {
    fireEvent('Full recording paused');
  } else {
    fireEvent('Full recording resumed');
  }
}
export function trackPauseResumeSegmentRecording(isPaused: boolean) {
  if (isPaused) {
    fireEvent('Segment recording paused');
  } else {
    fireEvent('Segment recording resumed');
  }
}

export function trackPlayAudio(caseId: number) {
  fireEvent('Audio playing', getAnalyticsCase(caseId));
}

export function trackPauseAudio(caseId: number) {
  fireEvent('Audio paused', getAnalyticsCase(caseId));
}

export function trackEnableSilenceCut(caseId: number) {
  fireEvent('Silence cut enabled', getAnalyticsCase(caseId));
}

export function trackDisableSilenceCut(caseId: number) {
  fireEvent('Silence cut disabled', getAnalyticsCase(caseId));
}

export function trackPlaybackSpeedChange(caseId: number, speed: number) {
  let caseObj = getAnalyticsCase(caseId);
  fireEvent('Playback speed changed', { ...caseObj, speed: speed });
}

export function trackStoringAndTranscribingSegmentAudioRequested(
  caseId: number,
  isSelfHostedWhisper: boolean,
  attemptNumber: number,
) {
  const caseObj = getAnalyticsCase(caseId);
  fireEvent('Segment audio storing and transcribing requested', {
    ...caseObj,
    isSelfHostedWhisper,
    attemptNumber,
  });
}
export function trackStoringFullAudioRequested(
  caseId: number,
  isSelfHostedWhisper: boolean, // doesn't make much sense for simple storing of the full audio; kept for easier abstraction.
  attemptNumber: number,
) {
  const caseObj = getAnalyticsCase(caseId);
  fireEvent('Full audio storing requested', { ...caseObj, isSelfHostedWhisper, attemptNumber });
}

export function trackComingBackOnline() {
  fireEvent('The app has come back online.');
}

export function trackTokenUpdatedAfterComingBackOnline() {
  fireEvent('The auth token has been updated after coming back online.');
}

export function trackTokenUpdatedOnReceivingNewCase() {
  fireEvent('The auth token has been updated on receiving a new case.');
}
