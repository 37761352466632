import '@mdxeditor/editor/style.css';
import type { ICaseNoteStructured } from '@/types/ICaseNoteStructured';
import { getDefaultNoteStructure } from '@/utils/note';
import { cloudCards, noteFieldTextStyles } from '@/utils/theme';

import {
  listsPlugin,
  MDXEditor,
  directivesPlugin,
  AdmonitionDirectiveDescriptor,
  toolbarPlugin,
  thematicBreakPlugin,
  quotePlugin,
  codeBlockPlugin,
  BoldItalicUnderlineToggles,
  Separator,
  diffSourcePlugin,
} from '@mdxeditor/editor';
import type { MDXEditorMethods } from '@mdxeditor/editor';
import { Button } from '@mui/material';
import type { InputBaseComponentProps } from '@mui/material';
import { forwardRef, useEffect, useRef, useState } from 'react';
import type { FC } from 'react';
import useCases from '@/hooks/useCases';
import { toJS } from 'mobx';
import './CaseStyles.css';
import { TemplateSearchBar } from './templates/TemplateSearch';
import { Box } from '@mui/material';
import AutoAwesomeIcon from '@mui/icons-material/AutoAwesome';
import UndoIcon from '@mui/icons-material/Undo';
import { purple, blue, green } from '@mui/material/colors';
import { fixNoteWithLLM } from '@/services/BackendAPI';
import { isAlphaSecretary } from '@/services/featureFlag';

interface IProps {
  dictations: string[];
  note: ICaseNoteStructured | null;
  readOnly?: boolean;
}

const StructuredNoteField: FC<IProps> = ({ dictations, note, readOnly = false }) => {
  let noteToDisplay: ICaseNoteStructured = {};

  if (note == null || Object.keys(note).length === 0) {
    noteToDisplay = getDefaultNoteStructure();
  } else {
    noteToDisplay = Object.assign({}, note);
  }

  const keys = Object.keys(noteToDisplay);

  return (
    <div
      style={{
        display: 'grid',
        paddingRight: keys.length > 1 ? '0.3rem' : 0,
        ...noteFieldTextStyles,
        overflowY: 'auto',
        width: '100%',
        height: '100%',
        whiteSpace: 'pre-wrap',
        overflow: 'hidden',
        border: '1px solid #dadada',
        borderRadius: 12,
        ...cloudCards,
      }}
    >
      {keys.map((key: string) => (
        <NoteField
          dictations={dictations}
          key={key}
          name={key}
          value={noteToDisplay[key]}
          readOnly={readOnly}
        />
      ))}
    </div>
  );
};

export default StructuredNoteField;

interface INoteField {
  dictations: string[];
  name: string;
  value: string;
  readOnly?: boolean;
  disabled?: boolean;
}
const NoteField: FC<INoteField> = ({ dictations, value, readOnly = false, disabled = false }) => {
  const cases = useCases();
  const ref = useRef<MDXEditorMethods>();
  const dictationRef = useRef(dictations);
  const initialNoteRef = useRef(null);
  const [llmLoading, setLlmLoading] = useState(false);
  const [preLlmText, setPreLlmText] = useState(null);

  function fixMarkdownText(input: string) {
    let output = input;
    // fixes user error with colons (not an issue once we always use the LLM fixer)
    output = output.replaceAll(/(?<!:):(?!(::|::note|\s))/g, ': ');
    output = output.replaceAll('```', '``');
    // fixes an over-aggressive replacement above that breaks markdown
    output = output.replaceAll(': **', ':**');
    return output;
  }

  value = fixMarkdownText(value);

  useEffect(() => {
    if (cases.noteStructured === null || initialNoteRef.current !== null || cases.isNew()) return;
    // on page load we want to initialize the editor with existing note content
    const initialNoteValue = toJS(cases.noteStructured);
    if (ref.current && initialNoteValue?.note) {
      ref.current.setMarkdown(fixMarkdownText(initialNoteValue.note));
    }
  }, [cases.noteStructured]);

  useEffect(() => {
    let timeout: NodeJS.Timeout;
    if (ref.current && dictations.length > 0 && dictationRef.current !== dictations) {
      // get all dictation items beyond the length of the current dictation list
      const newDictations = dictations.slice(dictationRef.current.length);

      dictationRef.current = dictations;
      newDictations.forEach((dictation) => {
        dictation = fixMarkdownText(dictation);
        ref.current.focus(null, { defaultSelection: 'rootEnd' });

        // check if dictation includes a space at the end or a line break
        // if so, insert the dictation without adding a zero-width space

        const currentDocumentationEmpty = ref.current.getMarkdown() === '&#x20;';
        if (currentDocumentationEmpty) {
          return ref.current.setMarkdown(dictation + '&#x20;');
        } else {
          return dictation.match(/\s$|\n&#x200b;$/)
            ? ref.current.insertMarkdown(dictation)
            : ref.current.insertMarkdown(dictation + '&#x20;');
        }
      });

      // update case note in backend
      timeout = setTimeout(() => {
        cases.updateCaseNoteSilently(ref.current.getMarkdown());
      }, 1);
    }

    return () => clearTimeout(timeout);
  }, [dictations, ref.current]);

  useEffect(() => {
    // if llmEditedNote updates, we want to update the editor content, unless it's the same as the current content, or null/empty
    if (
      cases?.llmEditedNote?.length > 100 &&
      cases?.llmEditedNote !== ref?.current?.getMarkdown()
    ) {
      ref.current.setMarkdown(cases.llmEditedNote);
    }
  }, [cases.llmEditedNote]);

  const handleInsertTemplate = (templateText: string) => {
    templateText = fixMarkdownText(templateText);
    const templateAdmonition = `:::note\n${templateText}\n:::\n\n`;

    const currentDocumentationEmpty = ref.current.getMarkdown() === '&#x20;';
    if (currentDocumentationEmpty) {
      ref.current.setMarkdown(templateAdmonition);
    } else {
      ref.current.insertMarkdown(templateAdmonition);
    }
    handleUpdateOnBlur();
    return moveCursorToEnd();
  };

  const handleLLMMagic = async () => {
    if (preLlmText) {
      if (confirm('Er du sikker på at du vil deaktivere Auto-rettelse?')) {
        ref.current.setMarkdown(preLlmText);
        setPreLlmText(null);
        cases.updateLLMEditedNote(null);
      }
      return;
    }
    setLlmLoading(true);
    const editorText = ref.current.getMarkdown();

    fixNoteWithLLM(editorText, cases.caseId)
      .then((updatedText) => {
        setPreLlmText(editorText);
        ref.current.setMarkdown(updatedText);
        cases.updateLLMEditedNote(updatedText);
        cases.updateCaseNoteSilently(updatedText);
        return setLlmLoading(false);
      })
      .catch((e) => {
        setPreLlmText(null);
        setLlmLoading(false);
        alert(e || 'Auto-rettelse kunne ikke anvendes på denne tekst');
      });
  };

  const handleUpdateOnBlur = async () => {
    // sleep to allow the editor to update the value before we get it

    // having researched this thoroughly, this is the only way to do it. Weirdly, only seems to be an issue if the note includes templates (:::note)
    await new Promise((resolve) => setTimeout(resolve, 100));
    const newValue = ref?.current?.getMarkdown();
    if (newValue) {
      cases.updateCaseNoteSilently(newValue);
    }
  };

  return (
    <MDXInputComponent
      ref={ref}
      value={value}
      readOnly={readOnly || disabled}
      handleInsertTemplate={handleInsertTemplate}
      handleLLMMagic={handleLLMMagic}
      llmLoading={llmLoading}
      llmEnabled={Boolean(preLlmText)}
      handleUpdateOnBlur={handleUpdateOnBlur}
    />
  );
};

interface MDXInputProps extends InputBaseComponentProps {
  value: string;
}

const MDXInputComponent = forwardRef<MDXEditorMethods, MDXInputProps>(
  (
    {
      value,
      readOnly,
      handleInsertTemplate,
      handleLLMMagic,
      handleUpdateOnBlur,
      llmLoading,
      llmEnabled,
    },
    ref,
  ) => {
    const previousValue = useRef(value);
    const updateCount = useRef(0);
    const cases = useCases();

    const [mdxDiffEnabled, setMdxDiffEnabled] = useState(false);

    const toggleMdxDiff = () => {
      // wait 1000ms to allow the editor to update the value before we get it
      setTimeout(
        () => {
          setMdxDiffEnabled((prev) => !prev);
        },
        mdxDiffEnabled ? 0 : 500,
      );
    };

    const buttonColor = llmEnabled ? blue : purple;

    const handleChange = (newValue: string) => {
      previousValue.current = newValue;
      updateCount.current = 0;
    };

    const handleBlur = () => {
      if (updateCount.current === 0) {
        handleUpdateOnBlur();
        updateCount.current++;
      }
    };

    return (
      <Box
        display="grid"
        sx={{ height: '100%', overflow: 'hidden', cursor: llmLoading ? 'progress' : 'unset' }}
      >
        <MDXEditor
          ref={ref}
          readOnly={readOnly || llmLoading}
          markdown={value || '&#x20;'}
          onChange={handleChange}
          onBlur={() => updateCount.current === 0 && handleBlur()}
          contentEditableClassName="contentEditable"
          className="mdxeditor"
          key={mdxDiffEnabled ? 'diff-mode' : 'rich-text-mode'}
          plugins={[
            toolbarPlugin({
              toolbarContents: () =>
                !cases.confirmedNote ? (
                  <>
                    <Box display="flex">
                      <BoldItalicUnderlineToggles />
                      <Separator />
                      <TemplateSearchBar handleInsertTemplate={handleInsertTemplate} />
                    </Box>
                    <Box display="flex" flexDirection="row" gap={0.5}>
                      {cases.noteStructured?.note !== cases.noteCorrected && isAlphaSecretary() && (
                        <Button
                          variant="outlined"
                          size="small"
                          onClick={toggleMdxDiff}
                          color="secondary"
                          sx={{
                            backgroundColor: blue[50],
                            '&:hover': {
                              backgroundColor: blue[100],
                              borderColor: blue[700],
                            },
                          }}
                        >
                          {mdxDiffEnabled ? 'Vis som tekst' : 'Vis ændringer'}
                        </Button>
                      )}
                      {!cases.isNew() && (
                        <Button
                          variant="outlined"
                          size="small"
                          onClick={handleLLMMagic}
                          disabled={llmLoading}
                          startIcon={llmEnabled ? <UndoIcon /> : <AutoAwesomeIcon />}
                          sx={{
                            backgroundColor: buttonColor[50],
                            color: buttonColor[500],
                            borderColor: buttonColor[500],
                            padding: '0.1rem 0.5rem',
                            '&:hover': {
                              backgroundColor: buttonColor[100],
                              borderColor: buttonColor[700],
                            },
                          }}
                        >
                          {llmLoading
                            ? 'Indlæser'
                            : llmEnabled
                              ? 'Deaktiver Auto-rettelse'
                              : 'Anvend Auto-rettelse'}
                        </Button>
                      )}
                    </Box>
                  </>
                ) : (
                  <ConfirmedBanner />
                ),
            }),
            listsPlugin(),
            thematicBreakPlugin(),
            quotePlugin(),
            codeBlockPlugin(),
            diffSourcePlugin({
              diffMarkdown: cases.noteCorrected,
              viewMode: mdxDiffEnabled ? 'diff' : 'rich-text',
              readOnlyDiff: true,
            }),
            directivesPlugin({ directiveDescriptors: [AdmonitionDirectiveDescriptor] }),
          ]}
        />
      </Box>
    );
  },
);

/**
 * move the cursor to the end of the text - leaves a blinking cursor, but
   otherwise we don't move the cursor to the end of the text on blur
 */
function moveCursorToEnd() {
  const editor = document.querySelector('.mdxeditor .contentEditable') as HTMLElement;
  if (editor) {
    const range = document.createRange();
    const sel = window.getSelection();
    range.selectNodeContents(editor);
    range.collapse(false);
    sel?.removeAllRanges();
    sel?.addRange(range);
  }
}

function ConfirmedBanner() {
  return (
    <Box
      sx={{
        backgroundColor: green.A100,
        color: green[800],
        width: '100%',
        textAlign: 'center',
        fontWeight: 'bold',
        borderRadius: 1,
        border: 1,
        fontSize: '1.2rem',
        padding: '0.1rem 0.5rem  ',
      }}
    >
      Bekræftet af lægen
    </Box>
  );
}
